import apiService from '../../adapters/api/apiService'

class ServicesService {
  async create(service) {
    const response = await apiService.createService(service)
    return response.data
  }

  async get(search = '') {
    const response = await apiService.getServices(search)
    return response.data
  }

  async getById(id) {
    const response = await apiService.getServices(id)
    return response.data?.pop() ?? {}
  }
}

export default new ServicesService()
