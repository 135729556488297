import apiService from '../../adapters/api/apiService'

class CategoryService {
  async getCategories(search) {
    const response = await apiService.getCategories(search)
    return response.data
  }
}

export default new CategoryService()
