import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Container, ListItem, ListItemText, Paper, Stack } from '@mui/material'

import MainLayout from '../common/MainLayout'
import _Table from '../common/Table'
import PTForm from '../common/PTForm'

import ProviderService from '../../core/usecases/providerService'

const PhysiciansForm = () => {
  const navigate = useNavigate()
  const formRef = React.useRef()

  const handleSave = async () => {
    const fd = formRef.current.fetchFormData()
    const { confirmpassword, ...form } = fd

    try {
      await ProviderService.create(form)
      navigate('/physicians')
    } catch (error) {
      console.error('Error in registartion module', error)
    }
  }

  return (
    <MainLayout location='Physicians'>
      <Stack
        elevation={0}
        sx={{ position: 'relative', display: 'flex', flex: 1 }}
      >
        <Paper
          elevation={0}
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 2
          }}
        >
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            elevation={0}
            sx={{
              background: (theme) => theme.palette.white.main,
              padding: '0 40px'
            }}
          >
            <ListItem>
              <ListItemText
                primary='Provider'
                secondary='You can add or edit provider on this page'
              />
            </ListItem>

            <Stack
              direction='row'
              elevation={0}
              spacing={2}
            >
              <Button
                color='buttonSecondary'
                variant='contained'
                elevation={0}
                disableElevation
                sx={{
                  textTransform: 'unset',
                  lineHeight: '20px',
                  borderRadius: '20px',
                  minWidth: '100px'
                }}
                onClick={() => navigate('/physicians')}
              >
                Cancel
              </Button>

              <Button
                color='buttonPrimary'
                variant='contained'
                elevation={0}
                disableElevation
                sx={{
                  textTransform: 'unset',
                  lineHeight: '20px',
                  borderRadius: '20px',
                  minWidth: '100px'
                }}
                onClick={handleSave}
              >
                Save
              </Button>
            </Stack>
          </Stack>
        </Paper>

        <Container
          disableGutters
          sx={{ height: '100%' }}
        >
          <Paper
            elevation={0}
            sx={{
              background: (theme) => theme.palette.background.main,
              padding: '40px 10%',
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              overflow: 'hidden'
            }}
          >
            <PTForm ref={formRef} />
          </Paper>
        </Container>
      </Stack>
    </MainLayout>
  )
}

export default PhysiciansForm
