import apiService from '../../adapters/api/apiService'

class CauseOfInjuryService {
  async create(CauseOfInjury) {
    const response = await apiService.createCauseOfInjuries(CauseOfInjury)
    return response.data
  }

  async lookup(search) {
    const response = await apiService.getCauseOfInjuries(search)
    return response.data
  }
}

export default new CauseOfInjuryService()
