import apiService from '../../adapters/api/apiService'

class DiagnosisService {
  async lookup(search) {
    const response = await apiService.getDiagnoses(search)
    return response.data
  }
}

export default new DiagnosisService()
