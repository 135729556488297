import React from 'react'
import { Button, FormControl, ListItemText, Stack, TextField } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import AutoComplete from './AutoComplete'
import DiagnosisService from '../../core/usecases/diagnosisService'

const ClientsIncidentDiagnosisAndInjuriesForm = ({ data, setData }) => {
  return (
    <Stack spacing={2}>
      <ListItemText
        primary='Injuries &'
        sx={{ textAlign: 'center' }}
      />

      <Stack
        direction='row'
        spacing={3}
        useFlexGap
        flexWrap='wrap'
      >
        <div style={{ width: '100%', flex: 1, flexShrink: 0, flexGrow: 1 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label='Injury Date'
              inputFormat='MM/DD/YYYY'
              value={data?.injury_date}
              onChange={(injury_date) => setData((p) => ({ ...p, injury_date }))}
              renderInput={(params) => <TextField {...params} />}
              sx={{
                background: (theme) => theme.palette.white.main,
                flexShrink: 0,
                display: 'flex',
                flex: 1
              }}
            />
          </LocalizationProvider>
        </div>

        <div style={{ width: '100%', flex: 1, flexShrink: 0, flexGrow: 1 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label='Contact Date'
              inputFormat='MM/DD/YYYY'
              value={data?.contact_date}
              onChange={(contact_date) => setData((p) => ({ ...p, contact_date }))}
              renderInput={(params) => <TextField {...params} />}
              sx={{
                background: (theme) => theme.palette.white.main,
                flexShrink: 0,
                display: 'flex',
                flex: 1
              }}
            />
          </LocalizationProvider>
        </div>
      </Stack>

      <Stack
        direction='row'
        spacing={3}
        useFlexGap
        flexWrap='wrap'
      >
        {/* <div style={{ width: '100%', flex: 1, flexShrink: 0, flexGrow: 1 }} /> */}
        <div style={{ width: '100%', flex: 1, flexShrink: 0, flexGrow: 1 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label='Assessment Date'
              inputFormat='MM/DD/YYYY'
              value={data?.assessment_date}
              onChange={(assessment_date) => setData((p) => ({ ...p, assessment_date }))}
              renderInput={(params) => <TextField {...params} />}
              sx={{
                background: (theme) => theme.palette.white.main,
                flexShrink: 0,
                display: 'flex',
                flex: 1
              }}
            />
          </LocalizationProvider>
        </div>

        <div style={{ width: '100%', flex: 1, flexShrink: 0, flexGrow: 1 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label='Last Doctor Visit'
              inputFormat='MM/DD/YYYY'
              value={data?.last_doctor_visit}
              onChange={(last_doctor_visit) => setData((p) => ({ ...p, last_doctor_visit }))}
              renderInput={(params) => <TextField {...params} />}
              sx={{
                background: (theme) => theme.palette.white.main,
                flexShrink: 0,
                display: 'flex',
                flex: 1
              }}
            />
          </LocalizationProvider>
        </div>
      </Stack>

      <Stack
        direction='row'
        spacing={3}
        useFlexGap
        flexWrap='wrap'
      >
        {/* <div style={{ width: '100%', flex: 1, flexShrink: 0, flexGrow: 1 }} /> */}
        <div style={{ width: '100%', flex: 1, flexShrink: 0, flexGrow: 1 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label='Next Doctor Visit'
              inputFormat='MM/DD/YYYY'
              value={data?.next_doctor_visit}
              onChange={(next_doctor_visit) => setData((p) => ({ ...p, next_doctor_visit }))}
              renderInput={(params) => <TextField {...params} />}
              sx={{
                background: (theme) => theme.palette.white.main,
                flexShrink: 0,
                display: 'flex',
                flex: 1
              }}
            />
          </LocalizationProvider>
        </div>

        <div style={{ width: '100%', flex: 1, flexShrink: 0, flexGrow: 1 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label='Next Eval Due Date'
              inputFormat='MM/DD/YYYY'
              value={data?.next_eval_due_date}
              onChange={(next_eval_due_date) => setData((p) => ({ ...p, next_eval_due_date }))}
              renderInput={(params) => <TextField {...params} />}
              sx={{
                background: (theme) => theme.palette.white.main,
                flexShrink: 0,
                display: 'flex',
                flex: 1
              }}
            />
          </LocalizationProvider>
        </div>
      </Stack>

      <Stack
        direction='row'
        spacing={3}
        useFlexGap
        flexWrap='wrap'
      >
        <div style={{ width: '100%', flex: 1, flexShrink: 0, flexGrow: 1 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label='Last Xray Date'
              inputFormat='MM/DD/YYYY'
              value={data?.last_xray_date}
              onChange={(last_xray_date) => setData((p) => ({ ...p, last_xray_date }))}
              renderInput={(params) => <TextField {...params} />}
              sx={{
                background: (theme) => theme.palette.white.main,
                flexShrink: 0,
                display: 'flex',
                flex: 1
              }}
            />
          </LocalizationProvider>
        </div>
        <div style={{ width: '100%', flex: 1, flexShrink: 0, flexGrow: 1 }} />
      </Stack>

      <ListItemText
        primary='Diagnoses'
        sx={{ textAlign: 'center' }}
      />

      {data?.incidentDiagnoses.map(({ id, description }, index) => (
        <Stack
          direction='row'
          spacing={2}
          useFlexGap
          flexWrap='wrap'
          key={`diagnoses-${index}`}
        >
          <AutoComplete
            label='Diagnosis'
            addOption
            value={id ?? null}
            handleAPI={DiagnosisService.lookup}
            lazy
            handleOnChange={(diagnosis) => {
              const { id, name, description } = diagnosis
              const incidentDiagnoses = (data?.incidentDiagnoses || []).slice()
              const updatedDiagnosis = { id, name, description }
              incidentDiagnoses[index] = updatedDiagnosis
              setData((p) => ({ ...p, incidentDiagnoses }))
            }}
            textFieldParams={{
              sx: {
                background: (theme) => theme.palette.white.main,
                width: '100%',
                flex: 1,
                flexShrink: 0,
                flexGrow: 1
              }
            }}
          />

          <FormControl
            sx={{
              background: (theme) => theme.palette.white.main,
              width: '100%',
              flex: 1,
              flexShrink: 0,
              flexGrow: 1
            }}
            variant='outlined'
          >
            <TextField
              label='Description'
              variant='outlined'
              value={description ?? ''}
              disabled
              fullWidth={false}
            />
          </FormControl>
        </Stack>
      ))}

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
        sx={{ alignItems: 'center', justifyContent: 'center' }}
      >
        <Button
          color='buttonBaseTheme'
          variant='contained'
          elevation={0}
          disableElevation
          sx={{
            textTransform: 'unset',
            lineHeight: '20px',
            borderRadius: '20px',
            minWidth: '100px'
          }}
          onClick={() => {
            const incidentDiagnoses = (data?.incidentDiagnoses ?? []).slice()
            incidentDiagnoses.push({})
            setData((p) => ({ ...p, incidentDiagnoses }))
          }}
        >
          Add Diagnosis
        </Button>
      </Stack>
    </Stack>
  )
}

export default ClientsIncidentDiagnosisAndInjuriesForm
