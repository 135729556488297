import apiService from '../../adapters/api/apiService'

class TreatmentLocationService {
  async create(TreatmentLocation) {
    const response = await apiService.createTreatmentLocations(TreatmentLocation)
    return response.data
  }

  async lookup(search) {
    const response = await apiService.getTreatmentLocations(search)
    return response.data
  }
}

export default new TreatmentLocationService()
