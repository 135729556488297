import apiService from '../../adapters/api/apiService'

class AuthService {
  async login(username, password) {
    const response = await apiService.login({ username, password })
    return response.data
  }

  async register(user) {
    const response = await apiService.register(user)
    return response.data
  }

  async getUsers(exception) {
    const response = await apiService.getUsers(exception)
    return response.data
  }
}

export default new AuthService()
