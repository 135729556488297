import { createContext, useContext, useMemo, useEffect, useState } from 'react'

const PermissionsContext = createContext()

export const PermissionsProvider = ({ children, userData }) => {
  const [permissions, setPermissions] = useState([])

  useEffect(() => {
    if (!!userData) {
      setPermissions(userData.permissions || [])
    }
  }, [userData])

  const value = useMemo(
    () => ({
      permissions,
      setPermissions
    }),
    [permissions]
  )

  return <PermissionsContext.Provider value={value}>{children}</PermissionsContext.Provider>
}

export const usePermissions = () => {
  return useContext(PermissionsContext)
}
