import React from 'react'
import {
  FormControl,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack
} from '@mui/material'

import AutoComplete from './AutoComplete'
import ColorPicker from './ColorPicker'

import billingOfficeService from '../../core/usecases/billingOfficeService'
import divisionService from '../../core/usecases/divisionService'
import serviceOfficeService from '../../core/usecases/serviceOfficeService'
import treatmentLocationService from '../../core/usecases/treatmentLocationService'

const DivisionDialog = ({ handleSubmit = () => {}, data = {}, handleClose = () => {} }) => {
  const [dialogValue, setDialogValue] = React.useState(data)
  const [loading, setLoading] = React.useState(false)
  React.useEffect(() => {
    setDialogValue(data)
  }, [data])

  const handleSave = (e) => {
    e.preventDefault()
    if (loading || !dialogValue?.name) return
    setLoading(true)

    Promise.all([
      divisionService.create({
        ...dialogValue,
        name: dialogValue.name,
        appointment_color_r: dialogValue?.appointment_color?.r ?? 255,
        appointment_color_g: dialogValue?.appointment_color?.g ?? 255,
        appointment_color_b: dialogValue?.appointment_color?.b ?? 255,
        appointment_color_a: dialogValue?.appointment_color?.a ?? 1
      })
    ])
      .then(([res]) => {
        handleSubmit(res)
      })
      .catch((error) => console.error('Error using divisionService.create', error))
      .finally(() => setLoading(false))
  }

  const handleAddServiceOffice = async (_serviceOffice) => {
    setLoading(true)
    await serviceOfficeService
      .create({ name: _serviceOffice.inputValue })
      .then((serviceOffice) => setDialogValue((p) => ({ ...p, serviceOffice: serviceOffice.id })))
      .catch((err) => console.error('Error using serviceOfficeService.create', err))
      .finally(() => setLoading(false))
  }

  const handleAddTreatmentLocation = async (_treatmentLocation) => {
    setLoading(true)
    await treatmentLocationService
      .create({ name: _treatmentLocation.inputValue })
      .then((treatmentLocation) =>
        setDialogValue((p) => ({ ...p, treatmentLocation: treatmentLocation.id }))
      )
      .catch((err) => console.error('Error using treatmentLocationService.create', err))
      .finally(() => setLoading(false))
  }

  const handleAddBillingOffice = async (_billingOffice) => {
    setLoading(true)
    await billingOfficeService
      .create({ name: _billingOffice.inputValue })
      .then((billingOffice) => setDialogValue((p) => ({ ...p, billingOffice: billingOffice.id })))
      .catch((err) => console.error('Error using billingOfficeService.create', err))
      .finally(() => setLoading(false))
  }

  return (
    <Dialog
      open
      onClose={handleClose}
    >
      <form onSubmit={handleSave}>
        <DialogTitle>Add a new division</DialogTitle>
        <DialogContent>
          <Stack
            direction='column'
            spacing={3}
            useFlexGap
            flexWrap='wrap'
          >
            <FormControl
              sx={{
                background: (theme) => theme.palette.white.main,
                width: '100%',
                flex: 1,
                flexShrink: 0,
                flexGrow: 1,
                marginTop: '7px'
              }}
              variant='outlined'
            >
              <TextField
                label='Division'
                variant='outlined'
                value={dialogValue.name ?? ''}
                fullWidth={false}
                onChange={(e) => setDialogValue((p) => ({ ...p, name: e.target.value }))}
              />
            </FormControl>

            <ColorPicker
              color={dialogValue?.appointment_color}
              onChangeColor={(c) => setDialogValue((p) => ({ ...p, appointment_color: { ...c } }))}
            />

            <AutoComplete
              label='Billing Office'
              handleAPI={billingOfficeService.lookup}
              value={dialogValue.billingOffice}
              handleOnChange={(billingOffice) =>
                setDialogValue((p) => ({
                  ...p,
                  billingOffice: billingOffice?.name || billingOffice
                }))
              }
              handleOnAdd={handleAddBillingOffice}
              free
              lazy
              addOption
              textFieldParams={{
                sx: {
                  background: (theme) => theme.palette.white.main,
                  width: '100%',
                  flex: 1,
                  flexShrink: 0,
                  flexGrow: 1
                }
              }}
            />

            <AutoComplete
              label='Service Office'
              addOption
              value={dialogValue.serviceOffice}
              handleAPI={serviceOfficeService.lookup}
              lazy
              handleOnChange={(serviceOffice) =>
                setDialogValue((p) => ({
                  ...p,
                  serviceOffice: serviceOffice?.id ?? null
                }))
              }
              handleOnAdd={handleAddServiceOffice}
              textFieldParams={{
                sx: {
                  background: (theme) => theme.palette.white.main,
                  width: '100%',
                  flex: 1,
                  flexShrink: 0,
                  flexGrow: 1
                }
              }}
            />

            <AutoComplete
              label='Treatment Location'
              addOption
              value={dialogValue.treatmentLocation}
              handleAPI={treatmentLocationService.lookup}
              lazy
              handleOnChange={(treatmentLocation) =>
                setDialogValue((p) => ({
                  ...p,
                  treatmentLocation: treatmentLocation?.id ?? null
                }))
              }
              handleOnAdd={handleAddTreatmentLocation}
              textFieldParams={{
                sx: {
                  background: (theme) => theme.palette.white.main,
                  width: '100%',
                  flex: 1,
                  flexShrink: 0,
                  flexGrow: 1
                }
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', paddingBottom: '25px' }}>
          <Button
            color='buttonBaseTheme'
            variant='contained'
            elevation={0}
            disableElevation
            sx={{
              textTransform: 'unset',
              lineHeight: '20px',
              borderRadius: '20px',
              minWidth: '100px'
            }}
            type='submit'
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default DivisionDialog
