import React, { forwardRef, useImperativeHandle } from 'react'
import { ListItemText, Stack, TextField, FormControl } from '@mui/material'
import Countries from 'countries-list'
import States from 'us-states'

import Select from './Select'
import Select2 from './Select2'

import providerService from '../../core/usecases/providerService'
import salutationService from '../../core/usecases/salutationService'

const ClientsClientForm = forwardRef(({ data, setData = () => {} }, ref) => {
  const [providers, setProviders] = React.useState([])
  const [salutations, setSalutations] = React.useState([])
  const [countries, __c] = React.useState(
    Object.keys(Countries.countries).map((code) => Countries.countries[code].name)
  )
  const [states, __s] = React.useState(Object.values(States))

  React.useEffect(() => {
    Promise.all([providerService.get(), salutationService.getSalutations()]).then(([_c, _s]) => {
      setProviders(_c.map((d) => ({ id: d.id, display_name: d.name })))
      setSalutations(_s.map((d) => ({ id: d.id, display_name: d.name })))
    })
  }, [])

  const handleChange = (e, t) => {
    e.preventDefault()
    setData((p) => ({ ...p, [t]: e.target.value }))
  }

  useImperativeHandle(ref, () => ({
    fetchFormData() {
      return data
    }
  }))

  return (
    <Stack
      spacing={2}
      sx={{ width: '45vw', margin: '0 auto' }}
    >
      <ListItemText primary='Patient Name' />

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <Select2
          id='salutation'
          label='Salutation'
          options={salutations}
          value={data.salutation_id}
          onChange={(salutation_id) => setData((p) => ({ ...p, salutation_id }))}
        />

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='First Name'
            variant='outlined'
            value={data.firstname}
            onChange={(e) => setData((p) => ({ ...p, firstname: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Middle Name'
            variant='outlined'
            value={data.middlename}
            onChange={(e) => handleChange(e, 'middlename')}
            fullWidth={false}
          />
        </FormControl>

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Last Name'
            variant='outlined'
            value={data.lastname}
            onChange={(e) => setData((p) => ({ ...p, lastname: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>

      <ListItemText primary='Contact Information' />

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Home Phone'
            variant='outlined'
            value={data.home_phone}
            onChange={(e) => handleChange(e, 'home_phone')}
            fullWidth={false}
          />
        </FormControl>

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Work Phone'
            variant='outlined'
            value={data.work_phone}
            onChange={(e) => setData((p) => ({ ...p, work_phone: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Mobile'
            variant='outlined'
            value={data.mobile}
            onChange={(e) => handleChange(e, 'mobile')}
            fullWidth={false}
          />
        </FormControl>

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Fax'
            variant='outlined'
            value={data.fax}
            onChange={(e) => setData((p) => ({ ...p, fax: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Personal Email'
            variant='outlined'
            value={data.personal_email}
            onChange={(e) => handleChange(e, 'personal_email')}
            fullWidth={false}
          />
        </FormControl>

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Work Email'
            variant='outlined'
            value={data.work_email}
            onChange={(e) => setData((p) => ({ ...p, work_email: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>

      <FormControl
        sx={{
          background: (theme) => theme.palette.white.main,
          width: '100%',
          flex: 1,
          flexShrink: 0,
          flexGrow: 1
        }}
        variant='outlined'
      >
        <TextField
          label='Contact Notes'
          variant='outlined'
          value={data.contact_notes}
          onChange={(e) => setData((p) => ({ ...p, contact_notes: e.target.value }))}
          fullWidth={false}
        />
      </FormControl>

      <ListItemText primary='Address' />

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Street'
            variant='outlined'
            value={data.street}
            onChange={(e) => handleChange(e, 'street')}
            fullWidth={false}
          />
        </FormControl>

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='City'
            variant='outlined'
            value={data.city}
            onChange={(e) => setData((p) => ({ ...p, city: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <Select
          id='state'
          label='State'
          options={states}
          value={data.state}
          onChange={(state) => setData((p) => ({ ...p, state }))}
          disabled={!(data.country === 'United States')}
        />

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Zip Code'
            variant='outlined'
            value={data.postal}
            onChange={(e) => setData((p) => ({ ...p, postal: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>

      <Select
        id='country'
        label='Country'
        options={countries}
        value={data.country}
        onChange={(country) => setData((p) => ({ ...p, country }))}
      />

      <ListItemText primary='Physician' />

      <Select2
        id='providers'
        label='Physician'
        options={providers}
        value={data.provider_id}
        onChange={(provider_id) => setData((p) => ({ ...p, provider_id }))}
      />

      <ListItemText primary='Emergency Contact' />

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='First Name'
            variant='outlined'
            value={data.e_firstname}
            onChange={(e) => handleChange(e, 'e_firstname')}
            fullWidth={false}
          />
        </FormControl>

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Last Name'
            variant='outlined'
            value={data.e_lastname}
            onChange={(e) => setData((p) => ({ ...p, e_lastname: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Relationship'
            variant='outlined'
            value={data.e_relationship}
            onChange={(e) => handleChange(e, 'e_relationship')}
            fullWidth={false}
          />
        </FormControl>

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Phone'
            variant='outlined'
            value={data.e_phone}
            onChange={(e) => setData((p) => ({ ...p, e_phone: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>
    </Stack>
  )
})

export default ClientsClientForm
