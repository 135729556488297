import React, { forwardRef, useImperativeHandle } from 'react'
import { ListItemText, Stack, TextField, FormControl } from '@mui/material'
import Countries from 'countries-list'
import States from 'us-states'

import Select from './Select'
import Select2 from './Select2'

import categoryService from '../../core/usecases/categoryService'
import salutationService from '../../core/usecases/salutationService'

const initialData = {
  category: '',
  npi_no: '',
  salutation: '',
  firstname: '',
  middlename: '',
  lastname: '',
  company_name: '',
  credentials: '',
  job_title: '',
  phone: '',
  other_phone: '',
  fax: '',
  email: '',
  website: '',
  contact_name: '',
  street: '',
  city: '',
  state: '',
  postal: '',
  country: '',
  secondary_license_no: '',
  employee_no: '',
  taxonomy_code: '',
  modifier1: '',
  modifier2: '',
  modifier3: '',
  modifier4: '',
  licensing_name: ''
}

const PTForm = forwardRef((props, ref) => {
  const [data, setData] = React.useState(initialData)
  const [categories, setCategories] = React.useState([])
  const [salutations, setSalutations] = React.useState([])
  const [countries, __c] = React.useState(
    Object.keys(Countries.countries).map((code) => Countries.countries[code].name)
  )
  const [states, __s] = React.useState(Object.values(States))

  React.useEffect(() => {
    Promise.all([categoryService.getCategories(), salutationService.getSalutations()]).then(
      ([_c, _s]) => {
        setCategories(_c.map((d) => d.name))
        setSalutations(_s.map((d) => d.name))
      }
    )
    return () => setData(initialData)
  }, [])

  const handleChange = (e, t) => {
    e.preventDefault()
    setData((p) => ({ ...p, [t]: e.target.value }))
  }

  useImperativeHandle(ref, () => ({
    fetchFormData() {
      return data
    }
  }))

  return (
    <Stack
      spacing={2}
      sx={{ width: '45vw', alignSelf: 'center' }}
    >
      <ListItemText primary='Profile Information' />

      <Select
        id='category'
        label='Category'
        options={categories}
        value={data.category}
        onChange={(category) => setData((p) => ({ ...p, category }))}
      />

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <Select
          id='salutation'
          label='Salutation'
          options={salutations}
          value={data.salutation}
          onChange={(salutation) => setData((p) => ({ ...p, salutation }))}
        />

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='First Name'
            variant='outlined'
            value={data.firstname}
            onChange={(e) => setData((p) => ({ ...p, firstname: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Middle Name'
            variant='outlined'
            value={data.middlename}
            onChange={(e) => handleChange(e, 'middlename')}
            fullWidth={false}
          />
        </FormControl>

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Last Name'
            variant='outlined'
            value={data.lastname}
            onChange={(e) => setData((p) => ({ ...p, lastname: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Company Name'
            variant='outlined'
            value={data.company_name}
            onChange={(e) => handleChange(e, 'company_name')}
            fullWidth={false}
          />
        </FormControl>

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Credentials'
            variant='outlined'
            value={data.credentials}
            onChange={(e) => setData((p) => ({ ...p, credentials: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Job Title'
            variant='outlined'
            value={data.job_title || ''}
            onChange={(e) => setData((p) => ({ ...p, job_title: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Licensing Name'
            variant='outlined'
            value={data.licensing_name || ''}
            onChange={(e) => setData((p) => ({ ...p, licensing_name: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>

      <ListItemText primary='Contact Information' />

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Phone'
            variant='outlined'
            value={data.phone}
            onChange={(e) => handleChange(e, 'phone')}
            fullWidth={false}
          />
        </FormControl>

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Other Phone'
            variant='outlined'
            value={data.other_phone}
            onChange={(e) => setData((p) => ({ ...p, other_phone: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Fax'
            variant='outlined'
            value={data.fax}
            onChange={(e) => handleChange(e, 'fax')}
            fullWidth={false}
          />
        </FormControl>

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Email'
            variant='outlined'
            value={data.email}
            onChange={(e) => setData((p) => ({ ...p, email: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Website'
            variant='outlined'
            value={data.website}
            onChange={(e) => handleChange(e, 'website')}
            fullWidth={false}
          />
        </FormControl>

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Contact Name'
            variant='outlined'
            value={data.contact_name}
            onChange={(e) => setData((p) => ({ ...p, contact_name: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>

      <ListItemText primary='Address' />

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Street'
            variant='outlined'
            value={data.street}
            onChange={(e) => handleChange(e, 'street')}
            fullWidth={false}
          />
        </FormControl>

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='City'
            variant='outlined'
            value={data.city}
            onChange={(e) => setData((p) => ({ ...p, city: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <Select
          id='state'
          label='State'
          options={states}
          value={data.state}
          onChange={(state) => setData((p) => ({ ...p, state }))}
          disabled={!(data.country === 'United States')}
        />

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Zip Code'
            variant='outlined'
            value={data.postal}
            onChange={(e) => setData((p) => ({ ...p, postal: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>

      <Select
        id='country'
        label='Country'
        options={countries}
        onChange={(country) => setData((p) => ({ ...p, country }))}
      />

      <ListItemText primary='Billing' />

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='NPI #'
            variant='outlined'
            value={data.npi_no || ''}
            onChange={(e) => setData((p) => ({ ...p, npi_no: e.target.value }))}
          />
        </FormControl>

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Secondary License #'
            variant='outlined'
            value={data.secondary_license_no}
            onChange={(e) => setData((p) => ({ ...p, secondary_license_no: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Employee #'
            variant='outlined'
            value={data.employee_no || ''}
            onChange={(e) => setData((p) => ({ ...p, employee_no: e.target.value }))}
          />
        </FormControl>

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Taxonomy Code'
            variant='outlined'
            value={data.taxonomy_code}
            onChange={(e) => setData((p) => ({ ...p, taxonomy_code: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <Select
          id='modifier-1'
          label='Modifier 1'
          options={['GP']}
          value={data.modifier1}
          onChange={(modifier1) => setData((p) => ({ ...p, modifier1 }))}
        />

        <Select
          id='modifier-2'
          label='Modifier 2'
          options={['GP']}
          value={data.modifier2}
          onChange={(modifier2) => setData((p) => ({ ...p, modifier2 }))}
        />
      </Stack>

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <Select
          id='modifier-3'
          label='Modifier 3'
          options={['GP']}
          value={data.modifier3}
          onChange={(modifier3) => setData((p) => ({ ...p, modifier3 }))}
        />

        <Select
          id='modifier-4'
          label='Modifier 4'
          options={['GP']}
          value={data.modifier4}
          onChange={(modifier4) => setData((p) => ({ ...p, modifier4 }))}
        />
      </Stack>
    </Stack>
  )
})

export default PTForm
