import * as React from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'

const AutoComplete = ({
  textFieldParams = {},
  label = '',
  handleAPI = undefined,
  handleOnAdd = () => {},
  addOption = false,
  handleOnChange = () => {},
  value = null,
  free = false,
  lazy = false
}) => {
  const [open, setOpen] = React.useState(false)
  const [options, setOptions] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [debounce, setDebounce] = React.useState()
  const filter = createFilterOptions()

  React.useEffect(() => {
    if (lazy) handleApiChanges()
    return () => {
      setOptions([])
      setLoading(false)
      clearTimeout(debounce)
      setDebounce()
      setOpen(false)
    }
  }, [])

  const handleApiChanges = (search = '') => {
    if (!handleAPI || loading) return
    setLoading(true)

    Promise.all([handleAPI(search)])
      .then(([res]) => {
        setOptions(res)
      })
      .catch((error) => console.error('Error using API', error))
      .finally(() => setLoading(false))
  }

  const handleValue = (val) => {
    if (typeof val === 'number') {
      if (!options.length) handleApiChanges('')
      return options.find((option) => option.id === val) || null
    }

    return val
  }

  return (
    <Autocomplete
      {...textFieldParams}
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      isOptionEqualToValue={(option, value) => {
        if (typeof value === 'string') {
          return option?.name === value
        }

        return option.id === value.id
      }}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option
        }

        if (option.name && !option.inputValue) {
          return option.name
        }

        if (option.name && option.inputValue) {
          return option.inputValue
        }

        return option.name
      }}
      options={options}
      loading={loading}
      value={handleValue(value)}
      freeSolo={free}
      autoHighlight
      onInputChange={(e) => {
        if (free) handleOnChange(e?.target?.value)
        else {
          if (value) {
            const index = options.findIndex((item) => item.id === value)
            if (index !== -1) handleOnChange(options[index])
          }
        }
      }}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          // timeout to avoid instant validation of the dialog's form.
          setTimeout(() => {
            handleOnAdd(newValue)
          })
        } else if (newValue && newValue.inputValue) {
          handleOnAdd(newValue)
        } else {
          handleOnChange(newValue)
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params)

        if (params.inputValue !== '' && addOption) {
          filtered.push({
            inputValue: params.inputValue,
            name: `Add "${params.inputValue}"`
          })
        }

        return filtered
      }}
      selectOnFocus
      handleHomeEndKeys
      renderOption={(props, option) => <li {...props}>{option.name}</li>}
      renderInput={(params) => (
        <TextField
          {...params}
          {...textFieldParams}
          label={label}
          value={value?.name ?? value}
          onKeyUp={(e) => {
            clearTimeout(debounce)
            setDebounce(setTimeout(() => handleApiChanges(e?.target?.value), 500))
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress
                    color='inherit'
                    size={20}
                  />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
        />
      )}
    />
  )
}

export default AutoComplete
