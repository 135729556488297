import apiService from '../../adapters/api/apiService'

class PlaceOfInjuryService {
  async create(PlaceOfInjury) {
    const response = await apiService.createPlaceOfInjuries(PlaceOfInjury)
    return response.data
  }

  async lookup(search) {
    const response = await apiService.getPlaceOfInjuries(search)
    return response.data
  }
}

export default new PlaceOfInjuryService()
