import apiService from '../../adapters/api/apiService'

class ReferrerService {
  async create(referrer) {
    const response = await apiService.createReferrer(referrer)
    return response.data
  }

  async lookup(search) {
    const response = await apiService.getReferrers(search)
    return response.data
  }
}

export default new ReferrerService()
