import apiService from '../../adapters/api/apiService'

class ReferralSourceService {
  async lookup(search) {
    const response = await apiService.getReferralSources(search)
    return response.data
  }
}

export default new ReferralSourceService()
