import React from 'react'
import { Button, Container, ListItemText, Paper, Stack, Tabs, Tab, Box } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import dayjs from 'dayjs'

import _Table from './Table'
import ClientsIncidentForm from './ClientsIncidentForm'
import ClientsIncidentDiagnosisAndInjuriesForm from './ClientsIncidentDiagnosisAndInjuriesForm'
import ClientsIncidentBillingRulesForm from './ClientsIncidentBillingRulesForm'

const ClientsIncidentsList = ({ data, setData = () => {}, handleParentNav = () => {} }) => {
  const [mode, setMode] = React.useState('list')
  const [tab, setTab] = React.useState(0)
  const [temp, setTemp] = React.useState(initialTemp)

  React.useEffect(() => {
    handleParentNav(mode === 'list')
    setTab(0)
  }, [mode])

  const handleSaveIncident = async () => {
    const incidents = (data?.incidents ?? []).slice()
    const fd = temp ?? {}

    try {
      incidents.push(fd)
      setData((p) => ({ ...p, incidents }))
      setTemp(initialTemp)
      setMode('list')
    } catch (error) {
      console.error('Error in handleSaveIncident', error)
    }
  }

  const handleChangeTab = (event, newValue) => {
    setTab(newValue)
  }

  return (
    <Stack
      spacing={2}
      {...(mode !== 'list' && { sx: { width: '45vw', margin: '0 auto' } })}
    >
      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        {mode === 'list' ? (
          <React.Fragment>
            <ListItemText primary='Incidents' />

            <Button
              color='buttonBaseTheme'
              variant='contained'
              elevation={0}
              disableElevation
              sx={{
                textTransform: 'unset',
                lineHeight: '20px',
                borderRadius: '20px',
                minWidth: '100px'
              }}
              onClick={() => setMode('form')}
            >
              Add Incident
            </Button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <ListItemText primary='New Incident' />

            <Stack
              direction='row'
              spacing={2}
              useFlexGap
              flexWrap='wrap'
            >
              <Button
                color='buttonBaseTheme'
                variant='outlined'
                elevation={0}
                disableElevation
                sx={{
                  textTransform: 'unset',
                  lineHeight: '20px',
                  borderRadius: '20px',
                  minWidth: '100px'
                }}
                onClick={() => {
                  setMode('list')
                  setTemp(initialTemp)
                }}
              >
                Cancel
              </Button>

              <Button
                color='buttonBaseTheme'
                variant='contained'
                elevation={0}
                disableElevation
                sx={{
                  textTransform: 'unset',
                  lineHeight: '20px',
                  borderRadius: '20px',
                  minWidth: '100px'
                }}
                onClick={handleSaveIncident}
              >
                Add
              </Button>
            </Stack>

            <Box
              sx={{
                borderBottom: (theme) => `solid 1px ${theme.palette.borderBackground.main}`,
                width: 1
              }}
            >
              <Tabs
                value={tab}
                onChange={handleChangeTab}
              >
                <Tab label='Incidents' />
                <Tab label='Injuries & Diagnoses' />
                <Tab label='Billing Rules' />
                {/* <Tab label='Schedule' />
                  <Tab label='Billing' /> */}
              </Tabs>
            </Box>
          </React.Fragment>
        )}
      </Stack>

      {mode === 'list' ? (
        <Container
          disableGutters
          sx={{ height: '100%' }}
        >
          <Paper
            elevation={0}
            sx={{
              background: (theme) => theme.palette.background.main,
              height: '100%'
            }}
          >
            <_Table
              columns={[
                'File',
                'Incident',
                'Incident Date',
                'Physician',
                'Clinic',
                'Billing',
                <MoreVertIcon />
              ]}
              rows={(data?.incidents ?? []).map((i) => [
                i.file_no,
                i.description,
                i.injury_date ? dayjs(new Date(i.injury_date)).format('MM/DD/YYYY') : '',
                i.incident_physician_name,
                i.treatment_location_name,
                i.billing_office,
                <MoreVertIcon
                  onClick={() => {
                    const {
                      assessment_date,
                      birthdate,
                      contact_date,
                      coverage_end_date,
                      coverage_start_date,
                      injury_date,
                      last_doctor_visit,
                      last_xray_date,
                      next_doctor_visit,
                      next_eval_due_date,
                      refferal_date,
                      incidentDiagnoses,
                      ...incident
                    } = i || {}
                    setTemp(() => ({
                      ...incident,
                      incidentDiagnoses: incidentDiagnoses.map(({ diagnosis_id }) => ({
                        id: diagnosis_id
                      })),
                      assessment_date: dayjs(assessment_date),
                      birthdate: dayjs(birthdate),
                      contact_date: dayjs(contact_date),
                      coverage_end_date: dayjs(coverage_end_date),
                      coverage_start_date: dayjs(coverage_start_date),
                      injury_date: dayjs(injury_date),
                      last_doctor_visit: dayjs(last_doctor_visit),
                      last_xray_date: dayjs(last_xray_date),
                      next_doctor_visit: dayjs(next_doctor_visit),
                      next_eval_due_date: dayjs(next_eval_due_date),
                      refferal_date: dayjs(refferal_date)
                    }))
                    setMode('form')
                  }}
                />
              ])}
            />
          </Paper>
        </Container>
      ) : (
        <React.Fragment>
          <CustomTabPanel
            value={tab}
            index={0}
          >
            <ClientsIncidentForm
              data={temp}
              setData={setTemp}
            />
          </CustomTabPanel>

          <CustomTabPanel
            value={tab}
            index={1}
          >
            <ClientsIncidentDiagnosisAndInjuriesForm
              data={temp}
              setData={setTemp}
            />
          </CustomTabPanel>

          <CustomTabPanel
            value={tab}
            index={2}
          >
            <ClientsIncidentBillingRulesForm
              data={temp}
              setData={setTemp}
            />
          </CustomTabPanel>
        </React.Fragment>
      )}
    </Stack>
  )
}

const CustomTabPanel = ({ value, index, children }) => {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`cf-tabpanel-${index}`}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

const initialTemp = {
  file_no: '',
  description: null,
  provider_id: null,
  cause_of_injury_id: null,
  place_of_injury_id: null,
  incident_physician_id: null,
  referrer_id: null,
  refferal_date: null,
  division_id: null,
  appointment_color: null,
  service_office_id: null,
  treatment_location_id: null,
  is_home_treatment: false,
  injury_date: null,
  contact_date: null,
  assessment_date: null,
  last_doctor_visit: null,
  next_doctor_visit: null,
  next_eval_due_date: null,
  last_xray_date: null,
  incidentDiagnoses: [{}],
  payor_id: null,
  payor_percentage: null,
  payor_total_units: null,
  payor_total_amount: null,
  payor_amount_per_day: null,
  payor_units_per_day: null,
  payor_total_visits: null,
  payor_include_tax_1: null,
  payor_include_tax_2: null,
  coverage_start_date: null,
  coverage_end_date: null,
  policy_no: null,
  policy_group_no: null,
  policy_id_no: null,
  claim_status: null,
  claim_preapproval_date: null,
  claim_authorization_no: null,
  claim_referral_no: null,
  claim_tracking_no: null,
  adjuster_id: null,
  adjuster_firstname: '',
  adjuster_lastname: '',
  adjuster_phone: '',
  adjuster_fax: '',
  policy_holder_type: null,
  policy_holder_salutation: null,
  policy_holder_firstname: null,
  policy_holder_middlename: null,
  policy_holder_lastname: null,
  contact_home_phone: null,
  contact_work_phone: null,
  contact_mobile: null,
  contact_fax: null,
  contact_personal_email: null,
  contact_work_email: null,
  street: null,
  city: null,
  state: null,
  country: null,
  postal: null,
  employer: null,
  gender: null,
  birthdate: null,
  relationship: null,
  ssn: null,

  // Non-form data
  provider_type: '',
  incident_physician_phone: '',
  incident_physician_fax: '',
  incident_physician_npi_no: '',
  referrer_phone: '',
  referrer_fax: '',
  referrer_company: '',
  incident_physician_name: '',
  treatment_location_name: '',
  referrer_source: '',
  billing_office: '',
  payor_name: '',
  payor_address: '',
  payor_phone: '',
  payor_fax: ''
}

export default ClientsIncidentsList
