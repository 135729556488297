import apiService from '../../adapters/api/apiService'

class ServiceOfficeService {
  async create(ServiceOffice) {
    const response = await apiService.createServiceOffices(ServiceOffice)
    return response.data
  }

  async lookup(search) {
    const response = await apiService.getServiceOffices(search)
    return response.data
  }
}

export default new ServiceOfficeService()
