import apiService from '../../adapters/api/apiService'

class ProviderService {
  async create(provider) {
    const response = await apiService.createProvider(provider)
    return response.data
  }

  async get() {
    const response = await apiService.getProviders()
    return response.data
  }

  async lookup(search) {
    const response = await apiService.getProvidersLookup(search)
    return response.data
  }

  async put(provider) {
    const response = await apiService.putProvider(provider)
    return response.data
  }
}

export default new ProviderService()
