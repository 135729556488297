import * as React from 'react'
import { useTheme } from '@mui/material/styles'
import { Box, OutlinedInput, InputLabel, MenuItem, FormControl, Select, Chip } from '@mui/material'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

function getStyles(v, l, theme) {
  return {
    fontWeight:
      l.indexOf(v) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium
  }
}

const MultipleSelect = ({ options = [], label = '', onChange = () => {}, id = '' }) => {
  const theme = useTheme()
  const [data, setData] = React.useState([])

  const handleChange = (event) => {
    const {
      target: { value }
    } = event
    setData(typeof value === 'string' ? value.split(',') : value)
    onChange(typeof value === 'string' ? value.split(',') : value)
  }

  return (
    <FormControl
      sx={{
        width: '100%',
        background: theme.palette.white.main,
        flex: 1,
        flexGrow: 1,
        flexShrink: 0
      }}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        value={data}
        onChange={handleChange}
        input={<OutlinedInput label={label} />}
        autoWidth={false}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value, k) => (
              <Chip
                key={`select-${id}-chip-${k}`}
                label={value}
              />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {options.map((v, k) => (
          <MenuItem
            key={`select-${id}-item-${k}`}
            value={v}
            style={getStyles(v, data, theme)}
          >
            {v}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default MultipleSelect
