import apiService from '../../adapters/api/apiService'

class ClientService {
  async create(client) {
    const response = await apiService.createClient(client)
    return response.data
  }

  async get() {
    const response = await apiService.getClients()
    return response.data
  }

  async getById(clientId) {
    const response = await apiService.getClientById(clientId)
    return response.data
  }
}

export default new ClientService()
