import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Container, ListItem, ListItemText, Paper, Stack } from '@mui/material'
import { useParams } from 'react-router-dom'

import MainLayout from '../common/MainLayout'
import _Table from '../common/Table'
import PackageMainForm from '../common/PackageMainForm'

import packageService from '../../core/usecases/packageService'

const initialData = {
  code: '',
  description: '',
  fees: []
}

const PackageForm = () => {
  const { packageId } = useParams()
  const navigate = useNavigate()
  const [data, setData] = React.useState(initialData)

  React.useEffect(() => {
    console.log(packageId, 'client id')
    if (packageId) {
      fetchServiceData(packageId)
    }
    return () => {
      setData(initialData)
    }
  }, [])

  const fetchServiceData = (_packageId) => {
    new Promise((resolve, reject) => {
      packageService
        .getById(_packageId)
        .then((res) => {
          const _form = Object.assign({}, res)
          setData((p) => ({ ...p, ..._form }))
          resolve(res)
        })
        .catch((err) => {
          console.error(`Error fetching package data ${err}`)
          reject(err)
        })
    })
  }

  const handleSave = async () => {
    if (packageId) return
    try {
      await packageService.create(data)
      navigate('/packages')
    } catch (error) {
      console.error('Error in services module', error)
    }
  }

  return (
    <MainLayout location='Package'>
      <Stack
        elevation={0}
        sx={{ position: 'relative', display: 'flex', flex: 1 }}
      >
        <Paper
          elevation={0}
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 2
          }}
        >
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            elevation={0}
            sx={{
              background: (theme) => theme.palette.white.main,
              padding: '0 40px'
            }}
          >
            <ListItem>
              <ListItemText
                primary='Add Package'
                secondary='You can add or edit package here'
              />
            </ListItem>

            <Stack
              direction='row'
              elevation={0}
              spacing={2}
            >
              <Button
                color='buttonBaseTheme'
                variant='outlined'
                elevation={0}
                disableElevation
                sx={{
                  textTransform: 'unset',
                  lineHeight: '20px',
                  borderRadius: '20px',
                  minWidth: '100px'
                }}
                onClick={() => navigate('/packages')}
              >
                Cancel
              </Button>

              <Button
                color='buttonBaseTheme'
                variant='contained'
                elevation={0}
                disableElevation
                sx={{
                  textTransform: 'unset',
                  lineHeight: '20px',
                  borderRadius: '20px',
                  minWidth: '100px'
                }}
                onClick={handleSave}
              >
                Save
              </Button>
            </Stack>
          </Stack>
        </Paper>

        <Container
          disableGutters
          sx={{
            height: '100%',
            width: '100%',
            overflow: 'hidden'
          }}
        >
          <Paper
            elevation={0}
            sx={{
              background: (theme) => theme.palette.background.main,
              padding: '40px 15px',
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              overflow: 'auto',
              height: '100%'
            }}
          >
            <PackageMainForm
              data={data}
              setData={setData}
            />
          </Paper>
        </Container>
      </Stack>
    </MainLayout>
  )
}

export default PackageForm
