import axios from 'axios'

const BASE_URL = process.env.REACT_APP_API

export default {
  async login(credentials) {
    return axios.post(`${BASE_URL}/auth/login`, credentials)
  },
  async register(user) {
    return axios.post(`${BASE_URL}/auth/register`, user)
  },
  async getUsers(exception) {
    return axios.get(`${BASE_URL}/auth/users/${exception}`)
  },
  async getRoles() {
    return axios.get(`${BASE_URL}/roles`)
  },
  async createRole(role) {
    return axios.post(`${BASE_URL}/roles/create`, role)
  },
  async getCategories(search) {
    return axios.get(`${BASE_URL}/category/lookup`, { params: { search } })
  },
  async getSalutations() {
    return axios.get(`${BASE_URL}/salutation`)
  },
  async createProvider(provider) {
    return axios.post(`${BASE_URL}/provider`, provider)
  },
  async putProvider(provider) {
    return axios.put(`${BASE_URL}/provider`, provider)
  },
  async getProviders() {
    return axios.get(`${BASE_URL}/provider`)
  },
  async getProvidersLookup(search) {
    return axios.get(`${BASE_URL}/provider/lookup`, { params: { search } })
  },
  async createClient(client) {
    return axios.post(`${BASE_URL}/client`, client)
  },
  async getClients() {
    return axios.get(`${BASE_URL}/client`)
  },
  async getClientById(clientId) {
    return axios.get(`${BASE_URL}/client/${clientId}`)
  },
  async getIncidentDescriptions(search) {
    return axios.get(`${BASE_URL}/incident-descriptions/lookup`, { params: { search } })
  },
  async createIncidentDescriptions(data) {
    return axios.post(`${BASE_URL}/incident-descriptions`, data)
  },
  async getModifiers(search) {
    return axios.get(`${BASE_URL}/modifiers/lookup`, { params: { search } })
  },
  async createModifiers(data) {
    return axios.post(`${BASE_URL}/modifiers`, data)
  },
  async getDivisions(search) {
    return axios.get(`${BASE_URL}/divisions/lookup`, { params: { search } })
  },
  async createDivisions(data) {
    return axios.post(`${BASE_URL}/divisions`, data)
  },
  async getTreatmentLocations(search) {
    return axios.get(`${BASE_URL}/treatment-locations/lookup`, { params: { search } })
  },
  async createTreatmentLocations(data) {
    return axios.post(`${BASE_URL}/treatment-locations`, data)
  },
  async getServiceOffices(search) {
    return axios.get(`${BASE_URL}/service-offices/lookup`, { params: { search } })
  },
  async createServiceOffices(data) {
    return axios.post(`${BASE_URL}/service-offices`, data)
  },
  async getPlaceOfInjuries(search) {
    return axios.get(`${BASE_URL}/place-of-injuries/lookup`, { params: { search } })
  },
  async createPlaceOfInjuries(data) {
    return axios.post(`${BASE_URL}/place-of-injuries`, data)
  },
  async getCauseOfInjuries(search) {
    return axios.get(`${BASE_URL}/cause-of-injuries/lookup`, { params: { search } })
  },
  async createCauseOfInjuries(data) {
    return axios.post(`${BASE_URL}/cause-of-injuries`, data)
  },
  async createReferrer(data) {
    return axios.post(`${BASE_URL}/referrer`, data)
  },
  async getReferrers(search) {
    return axios.get(`${BASE_URL}/referrer`, { params: { search } })
  },
  async getReferralSources(search) {
    return axios.get(`${BASE_URL}/referral-source`, { params: { search } })
  },
  async createBillingOffice(data) {
    return axios.post(`${BASE_URL}/billing-office`, data)
  },
  async getBillingOffices(search) {
    return axios.get(`${BASE_URL}/billing-office`, { params: { search } })
  },
  async createDiagnosis(data) {
    return axios.post(`${BASE_URL}/diagnosis`, data)
  },
  async getDiagnoses(search) {
    return axios.get(`${BASE_URL}/diagnosis`, { params: { search } })
  },
  async createPayor(data) {
    return axios.post(`${BASE_URL}/payors`, data)
  },
  async getPayors(search) {
    return axios.get(`${BASE_URL}/payors/lookup`, { params: { search } })
  },
  async createAdjuster(data) {
    return axios.post(`${BASE_URL}/adjusters`, data)
  },
  async getAdjusters(search) {
    return axios.get(`${BASE_URL}/adjusters/lookup`, { params: { search } })
  },
  async createService(data) {
    return axios.post(`${BASE_URL}/services`, data)
  },
  async getServices(search) {
    return axios.get(`${BASE_URL}/services/lookup`, { params: { search } })
  },
  async createPackage(data) {
    return axios.post(`${BASE_URL}/packages`, data)
  },
  async getPackages(search) {
    return axios.get(`${BASE_URL}/packages/lookup`, { params: { search } })
  }
}
