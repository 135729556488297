import React, { createContext, useState, useEffect, useMemo, useContext, Suspense } from 'react'
import { useLoaderData, useOutlet, Await, useNavigate } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

import { PermissionsProvider } from './PermissionsContext'

import theme from './../styles/theme'
import './../styles/index.css'

const AuthContext = createContext()

export const AuthProvider = ({ children, userData }) => {
  const [user, setUser] = useState(userData)
  const navigate = useNavigate()

  useEffect(() => {
    if (!user) navigate('/login')
  }, [user])

  const login = async (data) => {
    setUser(data)
    localStorage.setItem('_auth', JSON.stringify(data))
    navigate('/', { replace: true })
  }

  const logout = () => {
    setUser()
    navigate('/', { replace: true })
  }

  const value = useMemo(
    () => ({
      user,
      login,
      logout
    }),
    [user]
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}

export const AuthLayout = () => {
  const outlet = useOutlet()

  const { userPromise } = useLoaderData()

  return (
    <Suspense fallback={<div />}>
      <Await
        resolve={userPromise}
        errorElement={<div severity='error'>Something went wrong!</div>}
        children={(user) => (
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <PermissionsProvider userData={user}>
              <AuthProvider userData={user}>{outlet}</AuthProvider>
            </PermissionsProvider>
          </ThemeProvider>
        )}
      />
    </Suspense>
  )
}
