import React from 'react'
import {
  FormControl,
  ListItemText,
  Stack,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox
} from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import HomeIcon from '@mui/icons-material/Home'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import PrintIcon from '@mui/icons-material/Print'
import Countries from 'countries-list'
import States from 'us-states'

import AdjusterDialog from './AdjusterDialog'
import AutoComplete from './AutoComplete'
import PayorDialog from './PayorDialog'
import Select from './Select'

import adjusterService from '../../core/usecases/adjusterService'
import PayorService from '../../core/usecases/payorService'
import salutationService from '../../core/usecases/salutationService'

const ClientsIncidentBillingRulesForm = ({ data, setData }) => {
  const [countries, __c] = React.useState(
    Object.keys(Countries.countries).map((code) => Countries.countries[code].name)
  )
  const [states, __s] = React.useState(Object.values(States))
  const [dialog, setDialog] = React.useState()
  const [salutations, setSalutations] = React.useState([])

  React.useEffect(() => {
    Promise.all([salutationService.getSalutations()]).then(([_s]) => {
      setSalutations(_s.map((d) => d.name))
    })
  }, [])

  return (
    <Stack spacing={2}>
      <ListItemText primary='Payor' />
      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <AutoComplete
          label='Payor'
          addOption
          value={data?.payor_id}
          handleAPI={PayorService.lookup}
          lazy
          handleOnChange={(payor) =>
            setData((p) => ({
              ...p,
              payor_id: payor?.id ?? null,
              payor_name: payor?.name ?? '',
              payor_address: payor?.address ?? '',
              payor_phone: payor?.phone ?? '',
              payor_fax: payor?.fax ?? ''
            }))
          }
          handleOnAdd={(e) => {
            setDialog({ type: 'payor', name: e.inputValue })
            setData((p) => ({
              ...p,
              payor_id: null,
              payor_name: '',
              payor_address: '',
              payor_phone: '',
              payor_fax: ''
            }))
          }}
          textFieldParams={{
            sx: {
              background: (theme) => theme.palette.white.main,
              width: '100%',
              flex: 1,
              flexShrink: 0,
              flexGrow: 1
            }
          }}
        />
      </Stack>
      <Stack
        direction='row'
        spacing={3}
        useFlexGap
        flexWrap='wrap'
      >
        <div style={{ width: '100%', flex: 1, flexShrink: 0, flexGrow: 1 }}>
          <Stack
            direction='row'
            spacing={2}
            useFlexGap
            flexWrap='wrap'
            sx={{ width: '100%', flex: 1, flexShrink: 0, flexGrow: 1 }}
          >
            <table style={{ tableLayout: 'fixed', width: '100%' }}>
              <tbody>
                <tr>
                  <td>
                    <Stack
                      direction='row'
                      spacing={2}
                      useFlexGap
                      flexWrap='wrap'
                    >
                      <HomeIcon
                        fontSize='small'
                        color='buttonBaseTheme'
                      />
                      <Typography>Address:</Typography>
                    </Stack>
                  </td>
                  <td>
                    <Typography>{data?.payor_address}</Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Stack
                      direction='row'
                      spacing={2}
                      useFlexGap
                      flexWrap='wrap'
                    >
                      <LocalPhoneIcon
                        fontSize='small'
                        color='buttonBaseTheme'
                      />
                      <Typography>Phone:</Typography>
                    </Stack>
                  </td>
                  <td>
                    <Typography>{data?.payor_phone}</Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Stack
                      direction='row'
                      spacing={2}
                      useFlexGap
                      flexWrap='wrap'
                    >
                      <PrintIcon
                        fontSize='small'
                        color='buttonBaseTheme'
                      />
                      <Typography>Fax:</Typography>
                    </Stack>
                  </td>
                  <td>
                    <Typography>{data?.payor_fax}</Typography>
                  </td>
                </tr>
              </tbody>
            </table>
          </Stack>
        </div>
      </Stack>
      <ListItemText primary='Payor Portion' />
      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Percentage'
            variant='outlined'
            value={data.payor_percentage}
            onChange={(e) => setData((p) => ({ ...p, payor_percentage: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Total Units'
            variant='outlined'
            value={data.payor_total_units}
            onChange={(e) => setData((p) => ({ ...p, payor_total_units: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>
      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Total Amount'
            variant='outlined'
            value={data.payor_total_amount}
            onChange={(e) => setData((p) => ({ ...p, payor_total_amount: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Amount Per Day'
            variant='outlined'
            value={data.payor_amount_per_day}
            onChange={(e) => setData((p) => ({ ...p, payor_amount_per_day: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>
      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Units Per Day'
            variant='outlined'
            value={data.payor_units_per_day}
            onChange={(e) => setData((p) => ({ ...p, payor_units_per_day: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Total Visits'
            variant='outlined'
            value={data.payor_total_visits}
            onChange={(e) => setData((p) => ({ ...p, payor_total_visits: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>
      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <div style={{ width: '100%', flex: 1, flexShrink: 0, flexGrow: 1 }}>
          <FormControlLabel
            control={<Checkbox checked={data?.payor_include_tax_1} />}
            label='Include Tax'
            onChange={(e) => setData((p) => ({ ...p, payor_include_tax_1: e.target.checked }))}
          />
        </div>
        <div style={{ width: '100%', flex: 1, flexShrink: 0, flexGrow: 1 }}>
          <FormControlLabel
            control={<Checkbox checked={data?.payor_include_tax_2} />}
            label='Include Tax'
            onChange={(e) => setData((p) => ({ ...p, payor_include_tax_2: e.target.checked }))}
          />
        </div>
      </Stack>
      <ListItemText primary='Coverage Date' />
      <Stack
        direction='row'
        spacing={3}
        useFlexGap
        flexWrap='wrap'
      >
        <div style={{ width: '100%', flex: 1, flexShrink: 0, flexGrow: 1 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label='Start Date'
              inputFormat='MM/DD/YYYY'
              value={data?.coverage_start_date}
              onChange={(coverage_start_date) => setData((p) => ({ ...p, coverage_start_date }))}
              renderInput={(params) => <TextField {...params} />}
              sx={{
                background: (theme) => theme.palette.white.main,
                flexShrink: 0,
                display: 'flex',
                flex: 1
              }}
            />
          </LocalizationProvider>
        </div>

        <div style={{ width: '100%', flex: 1, flexShrink: 0, flexGrow: 1 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label='End Date'
              inputFormat='MM/DD/YYYY'
              value={data?.coverage_end_date}
              onChange={(coverage_end_date) => setData((p) => ({ ...p, coverage_end_date }))}
              renderInput={(params) => <TextField {...params} />}
              sx={{
                background: (theme) => theme.palette.white.main,
                flexShrink: 0,
                display: 'flex',
                flex: 1
              }}
            />
          </LocalizationProvider>
        </div>
      </Stack>
      <ListItemText primary='Policy Details' />
      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Policy# (Box 11/9D)'
            variant='outlined'
            value={data.policy_no}
            onChange={(e) => setData((p) => ({ ...p, policy_no: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Group# (Box 11C)'
            variant='outlined'
            value={data.policy_group_no}
            onChange={(e) => setData((p) => ({ ...p, policy_group_no: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>
      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='ID# (Box 1a/9a)'
            variant='outlined'
            value={data.policy_id_no}
            onChange={(e) => setData((p) => ({ ...p, policy_id_no: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>

        <div style={{ width: '100%', flex: 1, flexShrink: 0, flexGrow: 1 }} />
      </Stack>
      <ListItemText primary='Claim Details' />
      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <div style={{ width: '100%', flex: 1, flexShrink: 0, flexGrow: 1 }}>
          <FormControlLabel
            sx={{ whiteSpace: 'nowrap' }}
            control={<Checkbox checked={data?.claim_status === 'pending'} />}
            label='Pending'
            onChange={(e) => setData((p) => ({ ...p, claim_status: 'pending' }))}
          />
        </div>
        <div style={{ width: '100%', flex: 1, flexShrink: 0, flexGrow: 1 }}>
          <FormControlLabel
            sx={{ whiteSpace: 'nowrap' }}
            control={<Checkbox checked={data?.claim_status === 'no_preapproval'} />}
            label='No preapproval'
            onChange={(e) => setData((p) => ({ ...p, claim_status: 'no_preapproval' }))}
          />
        </div>
        <div style={{ width: '100%', flex: 1, flexShrink: 0, flexGrow: 1 }}>
          <FormControlLabel
            sx={{ whiteSpace: 'nowrap' }}
            control={<Checkbox checked={data?.claim_status === 'denied'} />}
            label='Denied'
            onChange={(e) => setData((p) => ({ ...p, claim_status: 'denied' }))}
          />
        </div>
        <div style={{ width: '100%', flex: 1, flexShrink: 0, flexGrow: 1 }}>
          <FormControlLabel
            sx={{ whiteSpace: 'nowrap' }}
            control={<Checkbox checked={data?.claim_status === 'preapproval_waived'} />}
            label='Preapproval waived'
            onChange={(e) => setData((p) => ({ ...p, claim_status: 'preapproval_waived' }))}
          />
        </div>
      </Stack>
      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <div style={{ width: '100%', flex: 1, flexShrink: 0, flexGrow: 0 }}>
          <FormControlLabel
            sx={{ whiteSpace: 'nowrap' }}
            control={<Checkbox checked={data?.claim_status === 'preapproval'} />}
            label='Preapproved as of'
            onChange={(e) => setData((p) => ({ ...p, claim_status: 'preapproval' }))}
          />
        </div>

        <div style={{ width: '100%', flex: 1, flexShrink: 0, flexGrow: 1, maxWidth: '200px' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label='Date'
              inputFormat='MM/DD/YYYY'
              value={data?.claim_preapproval_date}
              onChange={(claim_preapproval_date) =>
                setData((p) => ({ ...p, claim_preapproval_date }))
              }
              renderInput={(params) => <TextField {...params} />}
              sx={{
                background: (theme) => theme.palette.white.main,
                flexShrink: 0,
                display: 'flex',
                flex: 1
              }}
            />
          </LocalizationProvider>
        </div>
      </Stack>
      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='PolicyClaim/Auth# (Box 23)'
            variant='outlined'
            value={data.claim_authorization_no}
            onChange={(e) => setData((p) => ({ ...p, claim_authorization_no: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Referral Number'
            variant='outlined'
            value={data.claim_referral_no}
            onChange={(e) => setData((p) => ({ ...p, claim_referral_no: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>
      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Mediacare Tracking#'
            variant='outlined'
            value={data.claim_tracking_no}
            onChange={(e) => setData((p) => ({ ...p, claim_tracking_no: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>

        <div style={{ width: '100%', flex: 1, flexShrink: 0, flexGrow: 1 }} />
      </Stack>
      <ListItemText primary='Adjuster' />
      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <AutoComplete
          label='Adjuster'
          addOption
          value={data?.adjuster_id}
          handleAPI={adjusterService.lookup}
          lazy
          handleOnChange={(adjuster) =>
            setData((p) => ({
              ...p,
              adjuster_id: adjuster?.id ?? null,
              adjuster_firstname: adjuster?.firstname ?? '',
              adjuster_lastname: adjuster?.lastname ?? '',
              adjuster_phone: adjuster?.phone ?? '',
              adjuster_fax: adjuster?.fax ?? ''
            }))
          }
          handleOnAdd={(e) => {
            setDialog({ type: 'adjuster', name: e.inputValue })
            setData((p) => ({
              ...p,
              adjuster_id: null,
              adjuster_firstname: null,
              adjuster_lastname: null,
              adjuster_phone: null,
              adjuster_fax: null
            }))
          }}
          textFieldParams={{
            sx: {
              background: (theme) => theme.palette.white.main,
              width: '100%',
              flex: 1,
              flexShrink: 0,
              flexGrow: 1
            }
          }}
        />
      </Stack>
      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='First Name'
            variant='outlined'
            value={data.adjuster_firstname}
            onChange={(e) => setData((p) => ({ ...p, adjuster_firstname: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Last Name'
            variant='outlined'
            value={data.adjuster_lastname}
            onChange={(e) => setData((p) => ({ ...p, adjuster_lastname: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>
      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Phone'
            variant='outlined'
            value={data.adjuster_phone}
            onChange={(e) => setData((p) => ({ ...p, adjuster_phone: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Fax'
            variant='outlined'
            value={data.adjuster_fax}
            onChange={(e) => setData((p) => ({ ...p, adjuster_fax: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>
      <ListItemText primary='Policy Holder' />
      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <div style={{ width: '100%', flex: 1, flexShrink: 0, flexGrow: 1 }}>
          <FormControlLabel
            sx={{ whiteSpace: 'nowrap' }}
            control={<Checkbox checked={data?.policy_holder_type === 'client'} />}
            label='Client'
            onChange={(e) => setData((p) => ({ ...p, policy_holder_type: 'client' }))}
          />
        </div>
        <div style={{ width: '100%', flex: 1, flexShrink: 0, flexGrow: 1 }}>
          <FormControlLabel
            sx={{ whiteSpace: 'nowrap' }}
            control={<Checkbox checked={data?.policy_holder_type === 'guardian'} />}
            label='Guardian'
            onChange={(e) => setData((p) => ({ ...p, policy_holder_type: 'guardian' }))}
          />
        </div>
        <div style={{ width: '100%', flex: 1, flexShrink: 0, flexGrow: 1 }}>
          <FormControlLabel
            sx={{ whiteSpace: 'nowrap' }}
            control={<Checkbox checked={data?.policy_holder_type === 'spouse'} />}
            label='Spouse'
            onChange={(e) => setData((p) => ({ ...p, policy_holder_type: 'spouse' }))}
          />
        </div>
        <div style={{ width: '100%', flex: 1, flexShrink: 0, flexGrow: 1 }}>
          <FormControlLabel
            sx={{ whiteSpace: 'nowrap' }}
            control={<Checkbox checked={data?.policy_holder_type === 'other'} />}
            label='Other'
            onChange={(e) => setData((p) => ({ ...p, policy_holder_type: 'other' }))}
          />
        </div>
      </Stack>
      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <Select
          id='salutation'
          label='Salutation'
          options={salutations}
          value={data.policy_holder_salutation}
          onChange={(policy_holder_salutation) =>
            setData((p) => ({ ...p, policy_holder_salutation }))
          }
        />

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='First Name'
            variant='outlined'
            value={data.policy_holder_firstname}
            onChange={(e) => setData((p) => ({ ...p, policy_holder_firstname: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>
      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Middle Name'
            variant='outlined'
            value={data.policy_holder_middlename}
            onChange={(e) => setData((p) => ({ ...p, policy_holder_middlename: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Last Name'
            variant='outlined'
            value={data.policy_holder_lastname}
            onChange={(e) => setData((p) => ({ ...p, policy_holder_lastname: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>
      <ListItemText primary='Contact Information' />
      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Home Phone'
            variant='outlined'
            value={data.contact_home_phone}
            onChange={(e) => setData((p) => ({ ...p, contact_home_phone: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Work Phone'
            variant='outlined'
            value={data.contact_work_phone}
            onChange={(e) => setData((p) => ({ ...p, contact_work_phone: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>
      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Mobile'
            variant='outlined'
            value={data.contact_mobile}
            onChange={(e) => setData((p) => ({ ...p, contact_mobile: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Fax'
            variant='outlined'
            value={data.contact_fax}
            onChange={(e) => setData((p) => ({ ...p, contact_fax: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>
      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Personal Email'
            variant='outlined'
            value={data.contact_personal_email}
            onChange={(e) => setData((p) => ({ ...p, contact_personal_email: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Work Email'
            variant='outlined'
            value={data.contact_work_email}
            onChange={(e) => setData((p) => ({ ...p, contact_work_email: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>
      <ListItemText primary='Address' />
      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Street'
            variant='outlined'
            value={data.street}
            onChange={(e) => setData((p) => ({ ...p, street: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>
      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='City'
            variant='outlined'
            value={data.city}
            onChange={(e) => setData((p) => ({ ...p, city: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>

        <Select
          id='state'
          label='State'
          options={states}
          value={data.state}
          onChange={(state) => setData((p) => ({ ...p, state }))}
          disabled={!(data.country === 'United States')}
        />
      </Stack>
      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <Select
          id='country'
          label='Country'
          options={countries}
          value={data.country}
          onChange={(country) => setData((p) => ({ ...p, country }))}
        />

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Zip Code'
            variant='outlined'
            value={data.postal}
            onChange={(e) => setData((p) => ({ ...p, postal: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>
      <ListItemText primary='Other Details' />
      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Employer'
            variant='outlined'
            value={data.employer}
            onChange={(e) => setData((p) => ({ ...p, employer: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>
      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <Select
          id='gender'
          label='Gender'
          options={['Male', 'Female', 'Others']}
          value={data.gender}
          onChange={(gender) => setData((p) => ({ ...p, gender }))}
        />

        <div style={{ width: '100%', flex: 1, flexShrink: 0, flexGrow: 1 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label='Birthdate'
              inputFormat='MM/DD/YYYY'
              value={data?.birthdate}
              onChange={(birthdate) => setData((p) => ({ ...p, birthdate }))}
              renderInput={(params) => <TextField {...params} />}
              sx={{
                background: (theme) => theme.palette.white.main,
                flexShrink: 0,
                display: 'flex',
                flex: 1
              }}
            />
          </LocalizationProvider>
        </div>
      </Stack>
      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Relationship'
            variant='outlined'
            value={data.relationship}
            onChange={(e) => setData((p) => ({ ...p, relationship: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='SSN'
            variant='outlined'
            value={data.ssn}
            onChange={(e) => setData((p) => ({ ...p, ssn: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>
      {dialog?.type === 'payor' && (
        <PayorDialog
          data={dialog}
          handleClose={() => {
            setDialog()
            setData((p) => ({ ...p, payor_id: null }))
          }}
          handleSubmit={(e) => {
            setDialog()
            setData((p) => ({
              ...p,
              payor_id: e.id,
              payor_name: e.name ?? '',
              payor_address: e.address ?? '',
              payor_phone: e.phone ?? '',
              payor_fax: e.fax ?? ''
            }))
          }}
        />
      )}

      {dialog?.type === 'adjuster' && (
        <AdjusterDialog
          data={dialog}
          handleClose={() => {
            setDialog()
            setData((p) => ({ ...p, adjuster_id: null }))
          }}
          handleSubmit={(e) => {
            setDialog()
            setData((p) => ({
              ...p,
              adjuster_id: e.id,
              adjuster_firstname: e.firstname,
              adjuster_lastname: e.lastname,
              adjuster_phone: e.phone,
              adjuster_fax: e.fax
            }))
          }}
        />
      )}
    </Stack>
  )
}

export default ClientsIncidentBillingRulesForm
