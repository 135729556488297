import React from 'react'
import { Container, Paper, ListItemText, Stack, TextField, FormControl } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'

import _Table from './Table'
import AutoComplete from './AutoComplete'

import servicesService from '../../core/usecases/servicesService'

const PackageMainForm = ({ data, setData = () => {} }) => {
  const handleChangeData = (i, k, v) => {
    const _fees = data?.fees?.slice() || []
    _fees[i][k] = v
    setData((p) => ({ ...p, fees: _fees }))
  }

  return (
    <Stack
      spacing={2}
      sx={{ width: '70vw', margin: '0 auto' }}
    >
      <ListItemText primary='Description' />

      <FormControl
        sx={{
          background: (theme) => theme.palette.white.main,
          width: '100%',
          flex: 1,
          flexShrink: 0,
          flexGrow: 1
        }}
        variant='outlined'
      >
        <TextField
          label='Code'
          variant='outlined'
          value={data.code}
          onChange={(e) => setData((p) => ({ ...p, code: e.target.value }))}
          fullWidth={false}
        />
      </FormControl>

      <FormControl
        sx={{
          background: (theme) => theme.palette.white.main,
          width: '100%',
          flex: 1,
          flexShrink: 0,
          flexGrow: 1
        }}
        variant='outlined'
      >
        <TextField
          label='Description'
          variant='outlined'
          value={data.description}
          onChange={(e) => setData((p) => ({ ...p, description: e.target.value }))}
          fullWidth={false}
        />
      </FormControl>

      <ListItemText primary='Fee' />

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <Container
          disableGutters
          sx={{ height: '100%' }}
        >
          <Paper
            elevation={0}
            sx={{
              background: (theme) => theme.palette.background.main,
              height: '100%'
            }}
          >
            <_Table
              expand={1}
              columns={[
                'Fee',
                'Rate',
                'Duration',
                'Visits',
                'Modifier 1',
                'Modifier 2',
                'Modifier 3',
                'Modifier 4',
                <div
                  class='expanded'
                  style={{
                    textAlign: 'center',
                    cursor: 'pointer',
                    background: '#019592',
                    padding: 10
                  }}
                  onClick={() => {
                    const _fee = data?.fees?.slice() || []
                    _fee.push({ ...initialTemp })
                    setData((p) => ({ ...p, fees: _fee }))
                  }}
                >
                  <AddIcon color={'white'} />
                </div>
              ]}
              rows={(data?.fees ?? []).map((i, fi) => [
                <AutoComplete
                  label='Code'
                  value={data?.fees[fi]?.service_id || ''}
                  handleAPI={servicesService.get}
                  lazy
                  handleOnChange={(service) => {
                    const _fees = data.fees?.slice() || []
                    _fees[fi]['service_id'] = service?.id ?? ''
                    _fees[fi]['service_code'] = service?.code ?? ''
                    _fees[fi]['rate'] = service?.rate ?? ''
                    _fees[fi]['default_duration'] = service?.default_duration ?? ''
                    _fees[fi]['default_visits'] = service?.default_visits ?? ''
                    setData((p) => ({
                      ...p,
                      fees: p.fees
                    }))
                  }}
                  textFieldParams={{
                    sx: {
                      background: (theme) => theme.palette.white.main,
                      width: '100%',
                      flex: 1,
                      flexShrink: 0,
                      flexGrow: 1
                    }
                  }}
                />,
                <TextField
                  label='Rate'
                  variant='outlined'
                  // value={data?.fees[fi]?.rate || ''}
                  value={i?.rate || ''}
                  onChange={(e) => handleChangeData(fi, 'rate', e.target.value)}
                  fullWidth={false}
                />,
                <TextField
                  label='Duration'
                  variant='outlined'
                  // value={data?.fees[fi]?.default_duration || ''}
                  value={i?.default_duration || ''}
                  onChange={(e) => handleChangeData(fi, 'default_duration', e.target.value)}
                  fullWidth={false}
                />,
                <TextField
                  label='Visits'
                  variant='outlined'
                  // value={data?.fees[fi]?.default_visits || ''}
                  value={i?.default_visits || ''}
                  onChange={(e) => handleChangeData(fi, 'default_visits', e.target.value)}
                  fullWidth={false}
                />,
                <TextField
                  label='Modifier 1'
                  variant='outlined'
                  // value={data?.fees[fi]?.modifier1 || ''}
                  value={i?.modifier1 || ''}
                  onChange={(e) => handleChangeData(fi, 'modifier1', e.target.value)}
                  fullWidth={false}
                />,
                <TextField
                  label='Modifier 2'
                  variant='outlined'
                  // value={data?.fees[fi]?.modifier2 || ''}
                  value={i?.modifier2 || ''}
                  onChange={(e) => handleChangeData(fi, 'modifier2', e.target.value)}
                  fullWidth={false}
                />,
                <TextField
                  label='Modifier 3'
                  variant='outlined'
                  // value={data?.fees[fi]?.modifier3 || ''}
                  value={i?.modifier3 || ''}
                  onChange={(e) => handleChangeData(fi, 'modifier3', e.target.value)}
                  fullWidth={false}
                />,
                <TextField
                  label='Modifier 4'
                  variant='outlined'
                  // value={data?.fees[fi]?.modifier4 || ''}
                  value={i?.modifier4 || ''}
                  onChange={(e) => handleChangeData(fi, 'modifier4', e.target.value)}
                  fullWidth={false}
                />,
                <div
                  style={{ textAlign: 'center', cursor: 'pointer' }}
                  onClick={() => {
                    const _fees = data?.fees?.slice()
                    _fees.splice(fi, 1)
                    setData((p) => ({ ...p, fees: _fees }))
                  }}
                >
                  <CloseIcon color={'#828282'} />
                </div>
              ])}
            />
          </Paper>
        </Container>
      </Stack>
    </Stack>
  )
}

const initialTemp = {
  service_id: '',
  service_code: '',
  rate: '',
  default_duration: '',
  default_visits: '',
  modifier1: '',
  modifier2: '',
  modifier3: '',
  modifier4: ''
}

export default PackageMainForm
