import apiService from '../../adapters/api/apiService'

class DivisionService {
  async create(Division) {
    const response = await apiService.createDivisions(Division)
    return response.data
  }

  async lookup(search) {
    const response = await apiService.getDivisions(search)
    return response.data
  }
}

export default new DivisionService()
