import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#3700B3',
      light: '#6E00EE'
    },
    primaryLight: {
      main: '#6E00EE',
      contrastText: '#fff'
    },
    secondary: {
      main: '#005457',
      light: '#03DAC5',
      contrastText: '#fff'
    },
    highlight: {
      main: '#BB86FC1F',
      contrastText: '#3700B3'
    },
    white: {
      main: '#fff'
    },
    background: {
      main: '#f2f2f2',
      dark: '#e0e0e0'
    },
    buttonSecondary: {
      main: '#DBB2FF',
      contrastText: '#6200EE'
    },
    buttonPrimary: {
      main: '#6200EE',
      contrastText: '#fff'
    },
    buttonBaseTheme: {
      main: '#019592',
      contrastText: '#fff'
    },
    input: {
      main: '#000',
      contrastText: '#fff'
    },
    appbar: {
      main: '#019592',
      contrastText: '#fff'
    },
    link: {
      main: '#2196F3',
      contrastText: '#fff'
    },
    borderBackground: {
      main: '#D6D1D1'
    }
  },
  typography: {
    fontFamily: 'Roboto' // Customize your typography settings
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          // backgroundColor: 'red' // Change this to your desired background color
        },
        indicator: {
          backgroundColor: '#019592'
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minWidth: 'auto', // Change this to adjust the width of tabs
          '&.Mui-selected': {
            color: '#019592' // Change this to the selected tab's text color
          }
        }
      }
    }
  }
})

export default theme
