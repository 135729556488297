import apiService from '../../adapters/api/apiService'

class BillingOfficeService {
  async create(billingOffice) {
    const response = await apiService.createBillingOffice(billingOffice)
    return response.data
  }

  async lookup(search) {
    const response = await apiService.getBillingOffices(search)
    return response.data
  }
}

export default new BillingOfficeService()
