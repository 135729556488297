import React, { forwardRef, useImperativeHandle } from 'react'
import { ListItemText, Stack } from '@mui/material'

import Select from './Select'

const ClientsPaymentForm = forwardRef(({ data, setData = () => {} }, ref) => {
  useImperativeHandle(ref, () => ({
    fetchFormData() {
      return data
    }
  }))

  return (
    <Stack
      spacing={2}
      sx={{ width: '45vw', margin: '0 auto' }}
    >
      <ListItemText primary='Payment Method' />

      <Select
        id='mop'
        label='Mode of Payment'
        options={['Cash', 'Check']}
        value={data.mode_of_payment}
        onChange={(mode_of_payment) => setData((p) => ({ ...p, mode_of_payment }))}
      />
    </Stack>
  )
})

export default ClientsPaymentForm
