import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Container,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Tabs,
  Tab,
  Box
} from '@mui/material'
import { useParams } from 'react-router-dom'

import MainLayout from '../common/MainLayout'
import _Table from '../common/Table'
import ServiceFeeForm from '../common/ServiceFeeForm'
import ServicePayorsList from '../common/ServicePayorsList'

import ServiceService from '../../core/usecases/servicesService'

const initialData = {
  code: '',
  description: '',
  rate: '',
  rate_type: '',
  rate_count: '',
  default_duration: '',
  default_units: '',
  default_visits: '',
  post_treatment_description: '',
  provider_types: [''],
  payors: []
}

const ServicesForm = () => {
  const { serviceId } = useParams()
  const navigate = useNavigate()
  const [tab, setTab] = React.useState(0)
  const [data, setData] = React.useState(initialData)
  const [mainNav, setMainNav] = React.useState(true)

  React.useEffect(() => {
    console.log(serviceId, 'client id')
    if (serviceId) {
      fetchServiceData(serviceId)
    }
    return () => {
      setData(initialData)
      setMainNav(true)
      setTab(0)
    }
  }, [])

  const fetchServiceData = (_serviceId) => {
    new Promise((resolve, reject) => {
      ServiceService.getById(_serviceId)
        .then((res) => {
          const _form = Object.assign({}, res)
          setData((p) => ({ ...p, ..._form }))
          resolve(res)
        })
        .catch((err) => {
          console.error(`Error fetching service data ${err}`)
          reject(err)
        })
    })
  }

  const handleChangeTab = (event, newValue) => {
    setTab(newValue)
  }

  const handleSave = async () => {
    if (serviceId) return
    try {
      await ServiceService.create(data)
      navigate('/services')
    } catch (error) {
      console.error('Error in services module', error)
    }
  }

  return (
    <MainLayout location='Services'>
      <Stack
        elevation={0}
        sx={{ position: 'relative', display: 'flex', flex: 1 }}
      >
        <Paper
          elevation={0}
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 2
          }}
        >
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            elevation={0}
            sx={{
              background: (theme) => theme.palette.white.main,
              padding: '0 40px'
            }}
          >
            <ListItem>
              <ListItemText
                primary='Add Service'
                secondary='You can add or edit service here'
              />
            </ListItem>

            <Stack
              direction='row'
              elevation={0}
              spacing={2}
            >
              <Button
                color='buttonBaseTheme'
                variant='outlined'
                elevation={0}
                disableElevation
                sx={{
                  textTransform: 'unset',
                  lineHeight: '20px',
                  borderRadius: '20px',
                  minWidth: '100px'
                }}
                onClick={() => navigate('/services')}
              >
                Cancel
              </Button>

              <Button
                color='buttonBaseTheme'
                variant='contained'
                elevation={0}
                disableElevation
                sx={{
                  textTransform: 'unset',
                  lineHeight: '20px',
                  borderRadius: '20px',
                  minWidth: '100px'
                }}
                onClick={handleSave}
              >
                Save
              </Button>
            </Stack>
          </Stack>
        </Paper>

        <Container
          disableGutters
          sx={{
            height: '100%',
            width: '100%',
            overflow: 'hidden'
          }}
        >
          <Paper
            elevation={0}
            sx={{
              background: (theme) => theme.palette.background.main,
              padding: '40px 10%',
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              overflow: 'auto',
              height: '100%'
            }}
          >
            {mainNav && (
              <Box
                sx={{
                  borderBottom: (theme) => `solid 1px ${theme.palette.borderBackground.main}`
                }}
              >
                <Tabs
                  value={tab}
                  onChange={handleChangeTab}
                >
                  <Tab label='Fee' />
                  <Tab label='Payor Rates' />
                </Tabs>
              </Box>
            )}
            <CustomTabPanel
              value={tab}
              index={0}
            >
              <ServiceFeeForm
                data={data}
                setData={setData}
              />
            </CustomTabPanel>
            <CustomTabPanel
              value={tab}
              index={1}
            >
              <ServicePayorsList
                data={data}
                setData={setData}
              />
            </CustomTabPanel>
          </Paper>
        </Container>
      </Stack>
    </MainLayout>
  )
}

const CustomTabPanel = ({ value, index, children }) => {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`sf-tabpanel-${index}`}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

export default ServicesForm
