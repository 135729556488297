import React from 'react'
// import { useNavigate } from 'react-router-dom'

import { Button, Container, ListItem, ListItemText, Paper, Stack } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import MainLayout from '../common/MainLayout'
import _Table from '../common/Table'

import divisionService from '../../core/usecases/divisionService'

const LocationsList = () => {
  // const navigate = useNavigate()
  const [data, setData] = React.useState([])

  React.useEffect(() => {
    initializeTable()
    return () => setData([])
  }, [])

  const initializeTable = async () => {
    try {
      const _l = await divisionService.lookup()
      setData(_l)
    } catch (error) {
      console.error(error, 'Error initializing providers table')
    }
  }

  return (
    <MainLayout location='Locations'>
      <Stack
        elevation={0}
        sx={{ height: '100%' }}
      >
        <Paper elevation={0}>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            elevation={0}
            sx={{ background: (theme) => theme.palette.white.main, padding: '0 40px' }}
          >
            <ListItem>
              <ListItemText
                primary='Location List'
                // secondary='You can add or edit providers on this page'
              />
            </ListItem>

            {/* <Button
              color='primaryLight'
              variant='contained'
              elevation={0}
              disableElevation
              sx={{
                textTransform: 'unset',
                lineHeight: '20px',
                borderRadius: '20px',
                minWidth: '200px'
              }}
              onClick={() => navigate('/physicians/create')}
            >
              Add Provider
            </Button> */}
          </Stack>
        </Paper>

        <Container
          disableGutters
          sx={{ height: '100%' }}
        >
          <Paper
            elevation={0}
            sx={{
              background: (theme) => theme.palette.background.main,
              padding: '40px 10%',
              height: '100%'
            }}
          >
            <_Table
              columns={['Division', 'Billing Office', 'Service Office', '']}
              rows={data.map((d) => [d.name, d.billingOffice, d.serviceOffice, <MoreVertIcon />])}
            />
          </Paper>
        </Container>
      </Stack>
    </MainLayout>
  )
}

export default LocationsList
