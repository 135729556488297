import React from 'react'
import { Link } from 'react-router-dom'
import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  ListItemButton
} from '@mui/material'
import ImageIcon from '@mui/icons-material/Image'
import FavoriteIcon from '@mui/icons-material/Favorite'
import { useTheme } from '@mui/material/styles'
import { useAuth } from '../../context/AuthContext'

const SideNav = ({ location = '' }) => {
  const { user } = useAuth()

  return (
    <Box
      component='nav'
      sx={{ height: '100%' }}
    >
      <List disablePadding={true}>
        <ListItem sx={{ height: '64px', background: (theme) => theme.palette.white.main }}>
          <img
            alt='Logo'
            src={require('./../../assets/Logo.png')}
            style={{
              objectFit: 'contain',
              width: '50%',
              borderRadius: 0
            }}
          />
        </ListItem>

        <ListItem sx={{ background: (theme) => theme.palette.background.main }}>
          <ListItemAvatar>
            <Avatar>
              <ImageIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={user?.name ?? ''}
            secondary={user?.username ?? ''}
          />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemText primary='Account' />
        </ListItem>

        <Divider />

        {[
          {
            path: '/',
            text: 'User Management',
            active: location === 'User Management'
          },
          {
            path: '/physicians',
            text: 'Providers',
            active: location === 'Physicians'
          },
          {
            path: '/clients',
            text: 'Patient',
            active: location === 'Patients'
          }
        ].map((i, k) => (
          <NavItem
            key={`side-nav-1-item-${k}`}
            {...i}
          />
        ))}

        <Divider />

        <ListItem>
          <ListItemText primary='Maintenance' />
        </ListItem>

        <Divider />

        {[
          {
            path: '/locations',
            text: 'Locations',
            active: location === 'Locations'
          },
          {
            path: '/services',
            text: 'Services',
            active: location === 'Services'
          },
          {
            path: '/packages',
            text: 'Package',
            active: location === 'Package'
          }
        ].map((i, k) => (
          <NavItem
            key={`side-nav-1-item-${k}`}
            {...i}
          />
        ))}
      </List>
    </Box>
  )
}

export default SideNav

const NavItem = ({ path, text, active = false }) => {
  const theme = useTheme()
  return (
    <ListItem
      sx={{
        padding: 0
      }}
    >
      <ListItemButton
        component={Link}
        to={path}
        sx={{
          '&:hover': {
            backgroundColor: theme.palette.highlight.main,
            color: theme.palette.highlight.contrastText,
            '& svg': {
              fill: theme.palette.highlight.contrastText
            }
          },
          '& .MuiListItemIcon-root': {
            minWidth: 40
          },
          ...((active && {
            backgroundColor: theme.palette.highlight.main,
            color: theme.palette.highlight.contrastText,
            '& svg': {
              fill: theme.palette.highlight.contrastText
            }
          }) ||
            {})
        }}
      >
        <ListItemIcon>
          <FavoriteIcon />
        </ListItemIcon>
        <ListItemText primary={text} />
      </ListItemButton>
    </ListItem>
  )
}
