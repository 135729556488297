import apiService from '../../adapters/api/apiService'

class AdjusterService {
  async create(adjuster) {
    const response = await apiService.createAdjuster(adjuster)
    return response.data
  }

  async lookup(search) {
    const response = await apiService.getAdjusters(search)
    return response.data
  }
}

export default new AdjusterService()
