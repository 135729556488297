import React from 'react'
import {
  FormControl,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack
} from '@mui/material'

import adjusterService from '../../core/usecases/adjusterService'

const AdjusterDialog = ({ handleSubmit = () => {}, data = {}, handleClose = () => {} }) => {
  const [dialogValue, setDialogValue] = React.useState(data)
  const [loading, setLoading] = React.useState(false)
  React.useEffect(() => {
    setDialogValue(data)
  }, [data])

  const handleSave = (e) => {
    e.preventDefault()
    if (loading || !dialogValue?.name) return
    setLoading(true)

    Promise.all([adjusterService.create(dialogValue)])
      .then(([res]) => {
        handleSubmit(res)
      })
      .catch((error) => console.error('Error using adjusterService.create', error))
      .finally(() => setLoading(false))
  }

  return (
    <Dialog
      open
      onClose={handleClose}
    >
      <form onSubmit={handleSave}>
        <DialogTitle>Add a new adjuster</DialogTitle>
        <DialogContent>
          <Stack
            direction='column'
            spacing={3}
            useFlexGap
            flexWrap='wrap'
          >
            <FormControl
              sx={{
                background: (theme) => theme.palette.white.main,
                width: '100%',
                flex: 1,
                flexShrink: 0,
                flexGrow: 1,
                marginTop: '7px'
              }}
              variant='outlined'
            >
              <TextField
                label='Adjuster'
                variant='outlined'
                value={dialogValue.name ?? ''}
                fullWidth={false}
                onChange={(e) => setDialogValue((p) => ({ ...p, name: e.target.value }))}
              />
            </FormControl>

            <FormControl
              sx={{
                background: (theme) => theme.palette.white.main,
                width: '100%',
                flex: 1,
                flexShrink: 0,
                flexGrow: 1,
                marginTop: '7px'
              }}
              variant='outlined'
            >
              <TextField
                label='First Name'
                variant='outlined'
                value={dialogValue.firstname ?? ''}
                fullWidth={false}
                onChange={(e) => setDialogValue((p) => ({ ...p, firstname: e.target.value }))}
              />
            </FormControl>

            <FormControl
              sx={{
                background: (theme) => theme.palette.white.main,
                width: '100%',
                flex: 1,
                flexShrink: 0,
                flexGrow: 1,
                marginTop: '7px'
              }}
              variant='outlined'
            >
              <TextField
                label='Last Name'
                variant='outlined'
                value={dialogValue.lastname ?? ''}
                fullWidth={false}
                onChange={(e) => setDialogValue((p) => ({ ...p, lastname: e.target.value }))}
              />
            </FormControl>

            <FormControl
              sx={{
                background: (theme) => theme.palette.white.main,
                width: '100%',
                flex: 1,
                flexShrink: 0,
                flexGrow: 1,
                marginTop: '7px'
              }}
              variant='outlined'
            >
              <TextField
                label='Phone'
                variant='outlined'
                value={dialogValue.phone ?? ''}
                fullWidth={false}
                onChange={(e) => setDialogValue((p) => ({ ...p, phone: e.target.value }))}
              />
            </FormControl>

            <FormControl
              sx={{
                background: (theme) => theme.palette.white.main,
                width: '100%',
                flex: 1,
                flexShrink: 0,
                flexGrow: 1,
                marginTop: '7px'
              }}
              variant='outlined'
            >
              <TextField
                label='Fax'
                variant='outlined'
                value={dialogValue.fax ?? ''}
                fullWidth={false}
                onChange={(e) => setDialogValue((p) => ({ ...p, fax: e.target.value }))}
              />
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', paddingBottom: '25px' }}>
          <Button
            color='buttonBaseTheme'
            variant='contained'
            elevation={0}
            disableElevation
            sx={{
              textTransform: 'unset',
              lineHeight: '20px',
              borderRadius: '20px',
              minWidth: '100px'
            }}
            type='submit'
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default AdjusterDialog
