import * as React from 'react'
import { InputLabel, MenuItem, FormControl, Select as MaterialSelect } from '@mui/material'

const Select = ({
  label = '',
  onChange = () => {},
  options = [],
  id = '',
  value = '',
  ...rest
}) => {
  // const [data, setData] = React.useState(value)

  const handleChange = (event) => {
    // setData(event.target.value)
    onChange(event.target.value)
  }

  return (
    <FormControl sx={{ width: 1, flex: 1, flexGrow: 1, flexShrink: 0 }}>
      <InputLabel>{label}</InputLabel>
      <MaterialSelect
        value={value}
        onChange={handleChange}
        label={label}
        sx={{ background: (theme) => theme.palette.white.main }}
        {...rest}
      >
        {options.map((o, k) => (
          <MenuItem
            key={`select-${id}-item-${k}`}
            value={o.id}
          >
            {o.display_name}
          </MenuItem>
        ))}
      </MaterialSelect>
    </FormControl>
  )
}

export default Select
