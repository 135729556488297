import apiService from '../../adapters/api/apiService'

class SalutationService {
  async getSalutations() {
    const response = await apiService.getSalutations()
    return response.data
  }
}

export default new SalutationService()
