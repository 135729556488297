import React from 'react'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'

import incidentDescriptionService from '../../core/usecases/incidentDescriptionService'

const IncidentDescriptionDialog = ({
  handleSubmit = () => {},
  data = {},
  handleClose = () => {}
}) => {
  const [dialogValue, setDialogValue] = React.useState(data)
  const [loading, setLoading] = React.useState(false)
  React.useEffect(() => {
    setDialogValue(data)
  }, [data])

  const handleSave = (e) => {
    e.preventDefault()
    if (loading || !dialogValue?.name) return

    Promise.all([incidentDescriptionService.create({ name: dialogValue.name })])
      .then(([res]) => {
        handleSubmit(res)
      })
      .catch((error) => console.error('Error using incidentDescriptionService.create', error))
      .finally(() => setLoading(false))
  }

  return (
    <Dialog
      open
      onClose={handleClose}
    >
      <form onSubmit={handleSave}>
        <DialogTitle>Add a Incident Description</DialogTitle>
        <DialogContent>
          <DialogContentText>Incident Description Details</DialogContentText>
          <TextField
            autoFocus
            margin='dense'
            value={dialogValue?.name || ''}
            onChange={(event) =>
              setDialogValue({
                ...dialogValue,
                name: event.target.value
              })
            }
            label='Description'
            type='text'
            variant='outlined'
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type='submit'>Add</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default IncidentDescriptionDialog
