import React, { useState } from 'react'
import { Button, TextField, Paper, Box } from '@mui/material'

import Password from '../common/Password'

import authService from '../../core/usecases/authService'
import { useAuth } from '../../context/AuthContext'

import backgroundImage from './../../assets/LandingBackground.png'

const Login = () => {
  const { login } = useAuth()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const handleLogin = async () => {
    try {
      const user = await authService.login(username, password)
      login(user)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        width: '100%'
      }}
    >
      <Paper
        elevation={5}
        sx={{ padding: '30px', maxWidth: 350, textAlign: 'center' }}
      >
        <img
          src={require('./../../assets/Brand.png')}
          alt='Logo'
          style={{ marginBottom: '25px' }}
        />
        <h2>Login</h2>
        <form>
          <TextField
            label='Username'
            fullWidth
            margin='normal'
            sx={{ marginBottom: '25px' }}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <Password
            sx={{ marginBottom: '25px' }}
            label='Password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            color='buttonPrimary'
            variant='contained'
            disableElevation
            sx={{
              textTransform: 'unset',
              lineHeight: '20px',
              borderRadius: '20px',
              minWidth: '100px',
              marginBottom: '25px'
            }}
            onClick={handleLogin}
          >
            Submit
          </Button>
        </form>
      </Paper>
    </Box>
  )
}

export default Login
