import React from 'react'
import { Button, Container, ListItemText, Paper, Stack } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import _Table from './Table'
import ServicePayorForm from './ServicePayorForm'

const ServicePayorsList = ({ data, setData = () => {}, handleParentNav = () => {} }) => {
  const [mode, setMode] = React.useState('list')
  const [temp, setTemp] = React.useState(initialTemp)

  React.useEffect(() => {
    handleParentNav(mode === 'list')
  }, [mode])

  const handleSaveIncident = async () => {
    const payors = (data?.payors ?? []).slice()
    const fd = temp ?? {}

    try {
      payors.push(fd)
      setData((p) => ({ ...p, payors }))
      setTemp(initialTemp)
      setMode('list')
    } catch (error) {
      console.error('Error in handleSaveIncident', error)
    }
  }

  return (
    <Stack
      spacing={2}
      {...(mode !== 'list' && { sx: { width: '45vw', margin: '0 auto' } })}
    >
      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        {mode === 'list' ? (
          <React.Fragment>
            <ListItemText primary='' />

            <Button
              color='buttonBaseTheme'
              variant='contained'
              elevation={0}
              disableElevation
              sx={{
                textTransform: 'unset',
                lineHeight: '20px',
                borderRadius: '20px',
                minWidth: '100px'
              }}
              onClick={() => setMode('form')}
            >
              Add Payor
            </Button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <ListItemText primary='' />

            <Stack
              direction='row'
              spacing={2}
              useFlexGap
              flexWrap='wrap'
            >
              <Button
                color='buttonBaseTheme'
                variant='outlined'
                elevation={0}
                disableElevation
                sx={{
                  textTransform: 'unset',
                  lineHeight: '20px',
                  borderRadius: '20px',
                  minWidth: '100px'
                }}
                onClick={() => {
                  setMode('list')
                  setTemp(initialTemp)
                }}
              >
                Cancel
              </Button>

              <Button
                color='buttonBaseTheme'
                variant='contained'
                elevation={0}
                disableElevation
                sx={{
                  textTransform: 'unset',
                  lineHeight: '20px',
                  borderRadius: '20px',
                  minWidth: '100px'
                }}
                onClick={handleSaveIncident}
              >
                Add
              </Button>
            </Stack>
          </React.Fragment>
        )}
      </Stack>

      {mode === 'list' ? (
        <Container
          disableGutters
          sx={{ height: '100%' }}
        >
          <Paper
            elevation={0}
            sx={{
              background: (theme) => theme.palette.background.main,
              height: '100%'
            }}
          >
            <_Table
              columns={[
                'Payor',
                <div style={{ textAlign: 'right' }}>
                  <MoreVertIcon sx={{ textAlign: 'right' }} />
                </div>
              ]}
              rows={(data?.payors ?? []).map((i) => [
                i.payor_name,
                <div style={{ textAlign: 'right' }}>
                  {' '}
                  <MoreVertIcon
                    onClick={() => {
                      const {
                        payor_id,
                        payor_name,
                        rate,
                        rate_type,
                        rate_count,
                        default_duration,
                        default_units,
                        default_visits
                      } = i || {}
                      setTemp(() => ({
                        payor_id,
                        payor_name,
                        rate,
                        rate_type,
                        rate_count,
                        default_duration,
                        default_units,
                        default_visits
                      }))
                      setMode('form')
                    }}
                  />
                </div>
              ])}
            />
          </Paper>
        </Container>
      ) : (
        <ServicePayorForm
          data={temp}
          setData={setTemp}
        />
      )}
    </Stack>
  )
}

const initialTemp = {
  payor_id: '',
  rate: '',
  rate_type: '',
  rate_count: '',
  default_duration: '',
  default_units: '',
  default_visits: ''
}

export default ServicePayorsList
