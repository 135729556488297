import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, Container, ListItem, ListItemText, Paper, Stack } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import MainLayout from '../common/MainLayout'
import _Table from '../common/Table'

import authService from '../../core/usecases/authService'
import { useAuth } from '../../context/AuthContext'

const UserManagementList = () => {
  const { user } = useAuth()
  const navigate = useNavigate()
  const [data, setData] = React.useState([])

  React.useEffect(() => {
    initializeTable()
    return () => setData([])
  }, [])

  const initializeTable = async () => {
    try {
      const _l = await authService.getUsers(user.id)
      setData(_l)
    } catch (error) {
      console.error(error, 'Error initializing users table')
    }
  }

  return (
    <MainLayout location='User Management'>
      <Stack
        elevation={0}
        sx={{ height: '100%' }}
      >
        <Paper elevation={0}>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            elevation={0}
            sx={{ background: (theme) => theme.palette.white.main, padding: '0 40px' }}
          >
            <ListItem>
              <ListItemText
                primary='User Management'
                secondary='You can add or edit user on this page'
              />
            </ListItem>

            <Button
              color='primaryLight'
              variant='contained'
              elevation={0}
              disableElevation
              sx={{
                textTransform: 'unset',
                lineHeight: '20px',
                borderRadius: '20px',
                minWidth: '100px'
              }}
              onClick={() => navigate('/user/create')}
            >
              Add User
            </Button>
          </Stack>
        </Paper>

        <Container
          disableGutters
          sx={{ height: '100%' }}
        >
          <Paper
            elevation={0}
            sx={{
              background: (theme) => theme.palette.background.main,
              padding: '40px 10%',
              height: '100%'
            }}
          >
            <_Table
              columns={['User Name', 'Name', 'Role', 'Status', '']}
              rows={data.map((u) => [
                u.username,
                u.name,
                u.roles.join(', '),
                u.status,
                <MoreVertIcon />
              ])}
            />
          </Paper>
        </Container>
      </Stack>
    </MainLayout>
  )
}

export default UserManagementList
