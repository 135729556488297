import React from 'react'
import { Routes, Route } from 'react-router-dom'

// Pages
import UserManagementList from './../components/pages/UserManagementList'
import UserManagementForm from './../components/pages/UserManagementForm'
import PhysiciansList from './../components/pages/PhysiciansList'
import PhysiciansForm from './../components/pages/PhysiciansForm'
import ClientsList from './../components/pages/ClientsList'
import ClientForm from './../components/pages/ClientForm'
import LocationsList from './../components/pages/LocationsList'
import ServicesList from '../components/pages/ServicesList'
import ServicesForm from '../components/pages/ServicesForm'
import PackagesList from '../components/pages/PackagesList'
import PackageForm from '../components/pages/PackageForm'

function Protected() {
  return (
    <Routes>
      <Route
        path='/'
        element={<UserManagementList />}
      />
      <Route
        path='/user/create'
        element={<UserManagementForm />}
      />
      <Route
        path='/physicians'
        element={<PhysiciansList />}
      />
      <Route
        path='/physicians/create'
        element={<PhysiciansForm />}
      />
      <Route
        path='/clients'
        element={<ClientsList />}
      />
      <Route
        path='/clients/create'
        element={<ClientForm />}
      />
      <Route
        path='/clients/edit/:clientId'
        element={<ClientForm />}
      />
      <Route
        path='/locations'
        element={<LocationsList />}
      />
      <Route
        path='/services'
        element={<ServicesList />}
      />
      <Route
        path='/services/create'
        element={<ServicesForm />}
      />
      <Route
        path='/services/edit/:serviceId'
        element={<ServicesForm />}
      />
      <Route
        path='/packages'
        element={<PackagesList />}
      />
      <Route
        path='/packages/create'
        element={<PackageForm />}
      />
      <Route
        path='/packages/edit/:packageId'
        element={<PackageForm />}
      />
    </Routes>
  )
}

export default Protected
