import React from 'react'
import {
  FormControl,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack
} from '@mui/material'

import AutoComplete from './AutoComplete'
import Select from './Select'

import categoryService from '../../core/usecases/categoryService'
import ReferrerService from '../../core/usecases/referrerService'
import ReferralSourceService from '../../core/usecases/referralSourceService'

const ReferrerDialog = ({ handleSubmit = () => {}, handleClose = () => {} }) => {
  const [categories, setCategories] = React.useState([])
  const [dialogValue, setDialogValue] = React.useState({})
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    setLoading(true)
    Promise.all([categoryService.getCategories()]).then(([_c]) => {
      setCategories(_c.map((d) => d.name))
      setLoading(false)
    })

    return () => {
      setCategories([])
      setLoading(false)
      setDialogValue({})
    }
  }, [])

  const handleSave = (e) => {
    e.preventDefault()
    if (loading || !dialogValue?.name) return

    Promise.all([ReferrerService.create(dialogValue)])
      .then(([res]) => {
        handleSubmit(res)
      })
      .catch((error) => console.error('Error using referrerService.create', error))
      .finally(() => setLoading(false))
  }

  return (
    <Dialog
      open
      onClose={handleClose}
    >
      <form
        onSubmit={handleSave}
        style={{ width: '30vw', paddingTop: '10px' }}
      >
        <DialogTitle sx={{ textAlign: 'center' }}>Add Referral</DialogTitle>
        <DialogContent>
          <Stack
            direction='column'
            spacing={3}
            useFlexGap
            flexWrap='wrap'
          >
            <AutoComplete
              label='Referral Source'
              handleAPI={ReferralSourceService.lookup}
              value={dialogValue.refferalSource}
              handleOnChange={(referralSource) =>
                setDialogValue((p) => ({
                  ...p,
                  source: referralSource?.name || referralSource
                }))
              }
              free
              lazy
              textFieldParams={{
                sx: {
                  background: (theme) => theme.palette.white.main,
                  width: '100%',
                  flex: 1,
                  flexShrink: 0,
                  flexGrow: 1,
                  marginTop: '5px'
                }
              }}
            />

            <Select
              id='category'
              label='Category'
              options={categories}
              value={dialogValue.category ?? ''}
              onChange={(category) => setDialogValue((p) => ({ ...p, category }))}
            />

            <FormControl
              sx={{
                background: (theme) => theme.palette.white.main,
                width: '100%',
                flex: 1,
                flexShrink: 0,
                flexGrow: 1
              }}
              variant='outlined'
            >
              <TextField
                label='Name'
                variant='outlined'
                value={dialogValue.name ?? ''}
                fullWidth={false}
                onChange={(e) => setDialogValue((p) => ({ ...p, name: e.target.value }))}
              />
            </FormControl>
            <FormControl
              sx={{
                background: (theme) => theme.palette.white.main,
                width: '100%',
                flex: 1,
                flexShrink: 0,
                flexGrow: 1
              }}
              variant='outlined'
            >
              <TextField
                label='Phone'
                variant='outlined'
                value={dialogValue.phone ?? ''}
                fullWidth={false}
                onChange={(e) => setDialogValue((p) => ({ ...p, phone: e.target.value }))}
              />
            </FormControl>
            <FormControl
              sx={{
                background: (theme) => theme.palette.white.main,
                width: '100%',
                flex: 1,
                flexShrink: 0,
                flexGrow: 1
              }}
              variant='outlined'
            >
              <TextField
                label='Fax'
                variant='outlined'
                value={dialogValue.fax ?? ''}
                fullWidth={false}
                onChange={(e) => setDialogValue((p) => ({ ...p, fax: e.target.value }))}
              />
            </FormControl>
            <FormControl
              sx={{
                background: (theme) => theme.palette.white.main,
                width: '100%',
                flex: 1,
                flexShrink: 0,
                flexGrow: 1
              }}
              variant='outlined'
            >
              <TextField
                label='Company'
                variant='outlined'
                value={dialogValue.company ?? ''}
                fullWidth={false}
                onChange={(e) => setDialogValue((p) => ({ ...p, company: e.target.value }))}
              />
            </FormControl>
            <FormControl
              sx={{
                background: (theme) => theme.palette.white.main,
                width: '100%',
                flex: 1,
                flexShrink: 0,
                flexGrow: 1
              }}
              variant='outlined'
            >
              <TextField
                label='Website'
                variant='outlined'
                value={dialogValue.website ?? ''}
                fullWidth={false}
                onChange={(e) => setDialogValue((p) => ({ ...p, website: e.target.value }))}
              />
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', paddingBottom: '25px' }}>
          <Button
            color='buttonBaseTheme'
            variant='contained'
            elevation={0}
            disableElevation
            sx={{
              textTransform: 'unset',
              lineHeight: '20px',
              borderRadius: '20px',
              minWidth: '100px'
            }}
            type='submit'
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default ReferrerDialog
