import apiService from '../../adapters/api/apiService'

class IncidentDescriptionService {
  async create(incidentDescription) {
    const response = await apiService.createIncidentDescriptions(incidentDescription)
    return response.data
  }

  async lookup(search) {
    const response = await apiService.getIncidentDescriptions(search)
    return response.data
  }
}

export default new IncidentDescriptionService()
