import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Container, ListItem, ListItemText, Paper, Stack } from '@mui/material'

import MainLayout from '../common/MainLayout'
import _Table from '../common/Table'
import UserForm from '../common/UserForm'

import authService from '../../core/usecases/authService'

const UserManagementForm = () => {
  const navigate = useNavigate()
  const formRef = React.useRef()

  const handleSave = async () => {
    const fd = formRef.current.fetchFormData()
    const { confirmpassword, ...form } = fd

    try {
      await authService.register(form)
      navigate('/')
    } catch (error) {
      console.error('Error in registartion module', error)
    }
  }

  return (
    <MainLayout location='User Management'>
      <Stack
        elevation={0}
        sx={{ height: '100%', display: 'flex', flex: 1 }}
      >
        <Paper elevation={0}>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            elevation={0}
            sx={{ background: (theme) => theme.palette.white.main, padding: '0 40px' }}
          >
            <ListItem>
              <ListItemText
                primary='Add User'
                secondary='You can add or edit user on this page'
              />
            </ListItem>

            <Stack
              direction='row'
              elevation={0}
              spacing={2}
            >
              <Button
                color='buttonSecondary'
                variant='contained'
                elevation={0}
                disableElevation
                sx={{
                  textTransform: 'unset',
                  lineHeight: '20px',
                  borderRadius: '20px',
                  minWidth: '100px'
                }}
                onClick={() => navigate('/')}
              >
                Cancel
              </Button>

              <Button
                color='buttonPrimary'
                variant='contained'
                elevation={0}
                disableElevation
                sx={{
                  textTransform: 'unset',
                  lineHeight: '20px',
                  borderRadius: '20px',
                  minWidth: '100px'
                }}
                onClick={handleSave}
              >
                Save
              </Button>
            </Stack>
          </Stack>
        </Paper>

        <Container
          disableGutters
          sx={{ height: '100%' }}
        >
          <Paper
            elevation={0}
            sx={{
              background: (theme) => theme.palette.background.main,
              padding: '40px 10%',
              height: '100%'
            }}
          >
            <UserForm ref={formRef} />
          </Paper>
        </Container>
      </Stack>
    </MainLayout>
  )
}

export default UserManagementForm
