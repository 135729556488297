import React, { forwardRef, useImperativeHandle } from 'react'
import { ListItemText, Stack, TextField, FormControl } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import Select from './Select'

const ClientsDemographicsForm = forwardRef(({ data, setData = () => {} }, ref) => {
  const handleChange = (e, t) => {
    e.preventDefault()
    setData((p) => ({ ...p, [t]: e.target.value }))
  }

  useImperativeHandle(ref, () => ({
    fetchFormData() {
      return data
    }
  }))

  return (
    <Stack
      spacing={2}
      sx={{ width: '45vw', margin: '0 auto' }}
    >
      <ListItemText primary='Basic Information' />

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='File No'
            variant='outlined'
            value={data.file_no}
            onChange={(e) => setData((p) => ({ ...p, file_no: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>

        <Select
          id='gender'
          label='Gender'
          options={['Male', 'Female', 'Others']}
          value={data.gender}
          onChange={(gender) => setData((p) => ({ ...p, gender }))}
        />
      </Stack>

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label='Birthdate'
            inputFormat='MM/DD/YYYY'
            value={data.birthdate || undefined}
            onChange={(birthdate) => setData((p) => ({ ...p, birthdate }))}
            renderInput={(params) => <TextField {...params} />}
            sx={{
              background: (theme) => theme.palette.white.main,
              flexShrink: 0,
              display: 'flex',
              flex: 1
            }}
          />
        </LocalizationProvider>

        <Select
          id='ms'
          label='Marital Status'
          options={['Single', 'Married', 'Widow', 'Widower']}
          value={data.marital_status}
          onChange={(marital_status) => setData((p) => ({ ...p, marital_status }))}
        />
      </Stack>

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='SSN'
            variant='outlined'
            value={data.ssn}
            onChange={(e) => handleChange(e, 'ssn')}
            fullWidth={false}
          />
        </FormControl>

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Health Number'
            variant='outlined'
            value={data.health_no}
            onChange={(e) => setData((p) => ({ ...p, health_no: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Preferred Language'
            variant='outlined'
            value={data.preferred_language}
            onChange={(e) => setData((p) => ({ ...p, preferred_language: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>

        <Select
          id='race'
          label='Race'
          options={['Asian', 'Black', 'Caucasian', 'Hispanic']}
          value={data.race}
          onChange={(race) => setData((p) => ({ ...p, race }))}
        />
      </Stack>

      <FormControl
        sx={{
          background: (theme) => theme.palette.white.main,
          width: '100%',
          flex: 1,
          flexShrink: 0,
          flexGrow: 1
        }}
        variant='outlined'
      >
        <TextField
          label='Allergies'
          variant='outlined'
          value={data.allergies}
          onChange={(e) => setData((p) => ({ ...p, allergies: e.target.value }))}
          fullWidth={false}
        />
      </FormControl>

      <ListItemText primary="Driver's License" />

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Driver License No'
            variant='outlined'
            value={data.drivers_license_no}
            onChange={(e) => handleChange(e, 'drivers_license_no')}
            fullWidth={false}
          />
        </FormControl>

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Driver License Status'
            variant='outlined'
            value={data.drivers_license_status}
            onChange={(e) => setData((p) => ({ ...p, drivers_license_status: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>

      <ListItemText primary='Employment / School' />

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Employment Status'
            variant='outlined'
            value={data.employment_status}
            onChange={(e) => handleChange(e, 'employment_status')}
            fullWidth={false}
          />
        </FormControl>

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Employment / School'
            variant='outlined'
            value={data.employment_school}
            onChange={(e) => setData((p) => ({ ...p, employment_school: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>

      <FormControl
        sx={{
          background: (theme) => theme.palette.white.main,
          width: '100%',
          flex: 1,
          flexShrink: 0,
          flexGrow: 1
        }}
        variant='outlined'
      >
        <TextField
          label='Job Title'
          variant='outlined'
          value={data.job_title}
          onChange={(e) => setData((p) => ({ ...p, job_title: e.target.value }))}
          fullWidth={false}
        />
      </FormControl>

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Occupation'
            variant='outlined'
            value={data.occupation}
            onChange={(e) => handleChange(e, 'occupation')}
            fullWidth={false}
          />
        </FormControl>

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Supervisor'
            variant='outlined'
            value={data.supervisor}
            onChange={(e) => setData((p) => ({ ...p, supervisor: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>
    </Stack>
  )
})

export default ClientsDemographicsForm
