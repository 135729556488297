import React from 'react'
import {
  Button,
  FormControl,
  ListItemText,
  Stack,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography
} from '@mui/material'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import PrintIcon from '@mui/icons-material/Print'
import MedicalServicesIcon from '@mui/icons-material/MedicalServices'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import AutoComplete from './AutoComplete'
import _Table from './Table'
import DivisionDialog from './DivisionDialog'
import IncidentDescriptionDialog from './IncidentDescriptionDialog'
import TreatmentLocationDialog from './TreatmentLocationDialog'
import CauseOfInjuryDialog from './CauseOfInjuryDialog'
import ServiceOfficeDialog from './ServiceOfficeDialog'
import PlaceOfInjuryDialog from './PlaceOfInjuryDialog'
import ReferrerDialog from './referrerDialog'
import PhysicianDialog from './physicianDialog'
import ColorBox from './ColorBox'

import incidentDescriptionService from '../../core/usecases/incidentDescriptionService'
import providerService from '../../core/usecases/providerService'
import causeOfInjuryService from '../../core/usecases/causeOfInjuryService'
import placeOfInjuryService from '../../core/usecases/placeOfInjuryService'
import divisionService from '../../core/usecases/divisionService'
import serviceOfficeService from '../../core/usecases/serviceOfficeService'
import treatmentLocationService from '../../core/usecases/treatmentLocationService'
import referrerService from '../../core/usecases/referrerService'

const ClientsIncidentForm = ({ data, setData }) => {
  const [dialog, setDialog] = React.useState()

  // useImperativeHandle(ref, () => ({
  //   fetchFormData() {
  //     const _i = structuredClone(incident)
  //     return {
  //       ..._i,
  //       refferal_date: new Date(_i.refferal_date),
  //       appointment_color_r: _i?.appointment_color?.r ?? 255,
  //       appointment_color_g: _i?.appointment_color?.g ?? 255,
  //       appointment_color_b: _i?.appointment_color?.b ?? 255,
  //       appointment_color_a: _i?.appointment_color?.a ?? 1
  //     }
  //   }
  // }))

  return (
    <Stack spacing={2}>
      <ListItemText
        primary='Incident Details'
        sx={{ textAlign: 'center' }}
      />

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='File No'
            variant='outlined'
            value={data?.file_no}
            onChange={(e) => setData((p) => ({ ...p, file_no: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>

        <AutoComplete
          label='Description'
          addOption
          lazy
          handleAPI={incidentDescriptionService.lookup}
          value={data?.description}
          handleOnChange={(description) =>
            setData((p) => ({ ...p, description: description?.name || description }))
          }
          handleOnAdd={(e) => {
            setDialog({ type: 'incident description', name: e.inputValue })
            setData((p) => ({ ...p, description: e.inputValue }))
          }}
          free
          textFieldParams={{
            sx: {
              background: (theme) => theme.palette.white.main,
              width: '100%',
              flex: 1,
              flexShrink: 0,
              flexGrow: 1
            }
          }}
        />
      </Stack>

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <AutoComplete
          label='Provider'
          value={data?.provider_id}
          handleAPI={providerService.lookup}
          lazy
          handleOnChange={(provider) =>
            setData((p) => ({
              ...p,
              provider_id: provider?.id ?? null,
              provider_type: provider?.category ?? ''
            }))
          }
          textFieldParams={{
            sx: {
              background: (theme) => theme.palette.white.main,
              width: '100%',
              flex: 1,
              flexShrink: 0,
              flexGrow: 1
            }
          }}
        />

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Provider Type'
            variant='outlined'
            value={data?.provider_type ?? ''}
            disabled
            fullWidth={false}
          />
        </FormControl>
      </Stack>

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <AutoComplete
          label='Cause of Injury'
          addOption
          value={data?.cause_of_injury_id}
          handleAPI={causeOfInjuryService.lookup}
          lazy
          handleOnChange={(cause_of_injury) =>
            setData((p) => ({
              ...p,
              cause_of_injury_id: cause_of_injury?.id ?? null
            }))
          }
          handleOnAdd={(e) => {
            setDialog({ type: 'cause of injury', name: e.inputValue })
            setData((p) => ({ ...p, cause_of_injury_id: null }))
          }}
          textFieldParams={{
            sx: {
              background: (theme) => theme.palette.white.main,
              width: '100%',
              flex: 1,
              flexShrink: 0,
              flexGrow: 1
            }
          }}
        />

        <AutoComplete
          label='Place of Injury'
          addOption
          value={data?.place_of_injury_id}
          handleAPI={placeOfInjuryService.lookup}
          lazy
          handleOnChange={(place_of_injury) =>
            setData((p) => ({
              ...p,
              place_of_injury_id: place_of_injury?.id ?? null
            }))
          }
          handleOnAdd={(e) => {
            setDialog({ type: 'place of injury', name: e.inputValue })
            setData((p) => ({ ...p, place_of_injury_id: null }))
          }}
          textFieldParams={{
            sx: {
              background: (theme) => theme.palette.white.main,
              width: '100%',
              flex: 1,
              flexShrink: 0,
              flexGrow: 1
            }
          }}
        />
      </Stack>

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <ListItemText primary='Physician' />
        <ListItemText primary='Referral' />
      </Stack>

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <AutoComplete
          label='Incident Physician'
          value={data?.incident_physician_id}
          handleAPI={providerService.lookup}
          lazy
          handleOnChange={(provider) =>
            setData((p) => ({
              ...p,
              incident_physician_id: provider?.id ?? null,
              incident_physician_phone: provider?.phone ?? '',
              incident_physician_fax: provider?.fax ?? '',
              incident_physician_npi_no: provider?.npi_no ?? '',
              incident_physician_name: provider?.name ?? ''
            }))
          }
          textFieldParams={{
            sx: {
              background: (theme) => theme.palette.white.main,
              width: '100%',
              flex: 1,
              flexShrink: 0,
              flexGrow: 1
            }
          }}
        />

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Referral Source'
            variant='outlined'
            value={data?.referrer_source ?? ''}
            disabled
            fullWidth={false}
          />
        </FormControl>
      </Stack>

      <Stack
        direction='row'
        spacing={3}
        useFlexGap
        flexWrap='wrap'
      >
        <div style={{ width: '100%', flex: 1, flexShrink: 0, flexGrow: 1 }}>
          <Stack
            direction='row'
            spacing={2}
            useFlexGap
            flexWrap='wrap'
            sx={{ width: '100%', flex: 1, flexShrink: 0, flexGrow: 1 }}
          >
            <table style={{ tableLayout: 'fixed', width: '100%' }}>
              <tbody>
                <tr>
                  <td>
                    <Stack
                      direction='row'
                      spacing={2}
                      useFlexGap
                      flexWrap='wrap'
                    >
                      <LocalPhoneIcon
                        fontSize='small'
                        color='buttonBaseTheme'
                      />
                      <Typography>Phone:</Typography>
                    </Stack>
                  </td>
                  <td>
                    <Typography>{data?.incident_physician_phone}</Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Stack
                      direction='row'
                      spacing={2}
                      useFlexGap
                      flexWrap='wrap'
                    >
                      <PrintIcon
                        fontSize='small'
                        color='buttonBaseTheme'
                      />
                      <Typography>Fax:</Typography>
                    </Stack>
                  </td>
                  <td>
                    <Typography>{data?.incident_physician_fax}</Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Stack
                      direction='row'
                      spacing={2}
                      useFlexGap
                      flexWrap='wrap'
                    >
                      <MedicalServicesIcon
                        fontSize='small'
                        color='buttonBaseTheme'
                      />
                      <Typography>NPI:</Typography>
                    </Stack>
                  </td>
                  <td>
                    <Typography>{data?.incident_physician_npi_no}</Typography>
                  </td>
                </tr>
              </tbody>
            </table>
          </Stack>
        </div>
        <div style={{ width: '100%', flex: 1, flexShrink: 0, flexGrow: 1 }}>
          <AutoComplete
            label='Referrer'
            value={data?.referrer_id}
            handleAPI={referrerService.lookup}
            lazy
            handleOnChange={(referrer) =>
              setData((p) => ({
                ...p,
                referrer_id: referrer?.id ?? null,
                referrer_phone: referrer?.phone ?? '',
                referrer_fax: referrer?.fax ?? '',
                referrer_company: referrer?.company ?? '',
                referrer_source: referrer?.source ?? ''
              }))
            }
            textFieldParams={{
              sx: {
                background: (theme) => theme.palette.white.main,
                width: '100%',
                flex: 1,
                flexShrink: 0,
                flexGrow: 1
              }
            }}
          />
        </div>
      </Stack>

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
        mt={0}
      >
        <div style={{ width: '100%', flex: 1, flexShrink: 0, flexGrow: 1, marginTop: '8px' }}>
          <Button
            color='buttonBaseTheme'
            variant='contained'
            elevation={0}
            disableElevation
            sx={{
              textTransform: 'unset',
              lineHeight: '20px',
              borderRadius: '20px',
              minWidth: '100px'
            }}
            onClick={() =>
              setDialog({
                type: 'physician'
              })
            }
          >
            Add Physician
          </Button>
        </div>

        <Stack
          direction='row'
          spacing={2}
          useFlexGap
          flexWrap='wrap'
          sx={{ width: '100%', flex: 1, flexShrink: 0, flexGrow: 1 }}
        >
          <table style={{ tableLayout: 'fixed', width: '100%' }}>
            <tbody>
              <tr>
                <td>
                  <Stack
                    direction='row'
                    spacing={2}
                    useFlexGap
                    flexWrap='wrap'
                  >
                    <LocalPhoneIcon
                      fontSize='small'
                      color='buttonBaseTheme'
                    />
                    <Typography>Phone:</Typography>
                  </Stack>
                </td>
                <td>
                  <Typography>{data?.referrer_phone}</Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Stack
                    direction='row'
                    spacing={2}
                    useFlexGap
                    flexWrap='wrap'
                  >
                    <PrintIcon
                      fontSize='small'
                      color='buttonBaseTheme'
                    />
                    <Typography>Fax:</Typography>
                  </Stack>
                </td>
                <td>
                  <Typography>{data?.referrer_fax}</Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Stack
                    direction='row'
                    spacing={2}
                    useFlexGap
                    flexWrap='wrap'
                  >
                    <AccountBalanceIcon
                      fontSize='small'
                      color='buttonBaseTheme'
                    />
                    <Typography>Company:</Typography>
                  </Stack>
                </td>
                <td>
                  <Typography>{data?.referrer_company}</Typography>
                </td>
              </tr>
            </tbody>
          </table>
        </Stack>
      </Stack>

      <Stack
        direction='row'
        spacing={3}
        useFlexGap
        flexWrap='wrap'
      >
        <div style={{ width: '100%', flex: 1, flexShrink: 0, flexGrow: 1 }} />
        <div style={{ width: '100%', flex: 1, flexShrink: 0, flexGrow: 1 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label='Referral Date'
              inputFormat='MM/DD/YYYY'
              value={data?.refferal_date}
              onChange={(refferal_date) => setData((p) => ({ ...p, refferal_date: refferal_date }))}
              renderInput={(params) => <TextField {...params} />}
              sx={{
                background: (theme) => theme.palette.white.main,
                flexShrink: 0,
                display: 'flex',
                flex: 1
              }}
            />
          </LocalizationProvider>
        </div>
      </Stack>

      <Stack
        direction='row'
        spacing={3}
        useFlexGap
        flexWrap='wrap'
      >
        <div style={{ width: '100%', flex: 1, flexShrink: 0, flexGrow: 1 }} />
        <div style={{ width: '100%', flex: 1, flexShrink: 0, flexGrow: 1 }}>
          <Button
            color='buttonBaseTheme'
            variant='contained'
            elevation={0}
            disableElevation
            sx={{
              textTransform: 'unset',
              lineHeight: '20px',
              borderRadius: '20px',
              minWidth: '100px'
            }}
            onClick={() =>
              setDialog({
                type: 'referrer'
              })
            }
          >
            Add Referral
          </Button>
        </div>
      </Stack>

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <ListItemText primary='Administrative Details' />
        <ListItemText primary='Locations Details' />
      </Stack>

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <AutoComplete
          label='Division'
          addOption
          value={data?.division_id}
          handleAPI={divisionService.lookup}
          lazy
          handleOnChange={(division) =>
            setData((p) => ({
              ...p,
              division_id: division?.id ?? null,
              appointment_color: {
                r: division?.appointment_color_r ?? 255,
                g: division?.appointment_color_g ?? 255,
                b: division?.appointment_color_b ?? 255,
                a: division?.appointment_color_a ?? 1
              },
              billing_office: division?.billingOffice ?? '',
              service_office_id: division?.service_office_id ?? null,
              treatment_location_id: division?.treatment_location_id ?? null,
              treatment_location_name: division?.treatmentLocation ?? ''
            }))
          }
          handleOnAdd={(e) => {
            setDialog({
              type: 'division',
              name: e.inputValue,
              appointment_color: { r: 255, g: 255, b: 255, a: 255 }
            })
            setData((p) => ({ ...p, division_id: null, appointment_color: null }))
          }}
          textFieldParams={{
            sx: {
              background: (theme) => theme.palette.white.main,
              width: '100%',
              flex: 1,
              flexShrink: 0,
              flexGrow: 1
            }
          }}
        />

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Billing Office'
            variant='outlined'
            value={data?.billing_office ?? ''}
            disabled
            fullWidth={false}
          />
        </FormControl>
      </Stack>

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <ColorBox
          color={data?.appointment_color}
          onChangeColor={(appointment_color) =>
            setData((p) => ({
              ...p,
              appointment_color
            }))
          }
        />
        <AutoComplete
          label='Service Office'
          addOption
          value={data?.service_office_id}
          handleAPI={serviceOfficeService.lookup}
          lazy
          handleOnChange={(service_office) =>
            setData((p) => ({
              ...p,
              service_office_id: service_office?.id ?? null
            }))
          }
          handleOnAdd={(e) => {
            setDialog({
              type: 'service office',
              name: e.inputValue
            })
            setData((p) => ({ ...p, service_office_id: null }))
          }}
          textFieldParams={{
            sx: {
              background: (theme) => theme.palette.white.main,
              width: '100%',
              flex: 1,
              flexShrink: 0,
              flexGrow: 1
            }
          }}
        />
      </Stack>

      <Stack
        direction='row'
        spacing={3}
        useFlexGap
        flexWrap='wrap'
      >
        <div style={{ width: '100%', flex: 1, flexShrink: 0, flexGrow: 1 }} />
        <div style={{ width: '100%', flex: 1, flexShrink: 0, flexGrow: 1 }}>
          <AutoComplete
            label='Treatment Location'
            addOption
            value={data?.treatment_location_id}
            handleAPI={treatmentLocationService.lookup}
            lazy
            handleOnChange={(treatment_location) =>
              setData((p) => ({
                ...p,
                treatment_location_id: treatment_location?.id ?? null,
                treatment_location_name: treatment_location?.name ?? ''
              }))
            }
            handleOnAdd={(e) => {
              setDialog({
                type: 'treatment location',
                name: e.inputValue
              })
              setData((p) => ({
                ...p,
                treatment_location_id: null,
                treatment_location_name: ''
              }))
            }}
            textFieldParams={{
              sx: {
                background: (theme) => theme.palette.white.main,
                width: '100%',
                flex: 1,
                flexShrink: 0,
                flexGrow: 1
              }
            }}
          />
        </div>
      </Stack>

      <Stack
        direction='row'
        spacing={3}
        useFlexGap
        flexWrap='wrap'
      >
        <div style={{ width: '100%', flex: 1, flexShrink: 0, flexGrow: 1 }} />
        <div style={{ width: '100%', flex: 1, flexShrink: 0, flexGrow: 1 }}>
          <FormControlLabel
            control={<Checkbox checked={data?.is_home_treatment} />}
            label='Home Treatment'
            onChange={(e) => setData((p) => ({ ...p, is_home_treatment: e.target.checked }))}
          />
        </div>
      </Stack>

      {dialog?.type === 'incident description' && (
        <IncidentDescriptionDialog
          data={dialog}
          handleClose={() => {
            setDialog()
            setData((p) => ({ ...p, description: null }))
          }}
          handleSubmit={(e) => {
            setDialog()
            setData((p) => ({ ...p, description: e.name }))
          }}
        />
      )}

      {dialog?.type === 'cause of injury' && (
        <CauseOfInjuryDialog
          data={dialog}
          handleClose={() => {
            setDialog()
            setData((p) => ({ ...p, cause_of_injury_id: null }))
          }}
          handleSubmit={(e) => {
            setDialog()
            setData((p) => ({ ...p, cause_of_injury_id: e.id }))
          }}
        />
      )}

      {dialog?.type === 'place of injury' && (
        <PlaceOfInjuryDialog
          data={dialog}
          handleClose={() => {
            setDialog()
            setData((p) => ({ ...p, place_of_injury_id: null }))
          }}
          handleSubmit={(e) => {
            setDialog()
            setData((p) => ({ ...p, place_of_injury_id: e.id }))
          }}
        />
      )}

      {dialog?.type === 'division' && (
        <DivisionDialog
          data={dialog}
          handleClose={() => {
            setDialog()
            setData((p) => ({ ...p, division_id: null, appointment_color: null }))
          }}
          handleSubmit={(e) => {
            setDialog()
            setData((p) => ({
              ...p,
              division_id: e.id,
              appointment_color: {
                r: e?.appointment_color_r ?? 255,
                g: e?.appointment_color_g ?? 255,
                b: e?.appointment_color_b ?? 255,
                a: e?.appointment_color_a ?? 1
              },
              billing_office: e.billingOffice,
              service_office_id: e.service_office_id,
              treatment_location_id: e.treatment_location_id
            }))
          }}
        />
      )}

      {dialog?.type === 'service office' && (
        <ServiceOfficeDialog
          data={dialog}
          handleClose={() => {
            setDialog()
            setData((p) => ({ ...p, service_office_id: null }))
          }}
          handleSubmit={(e) => {
            setDialog()
            setData((p) => ({ ...p, service_office_id: e.id }))
          }}
        />
      )}

      {dialog?.type === 'treatment location' && (
        <TreatmentLocationDialog
          data={dialog}
          handleClose={() => {
            setDialog()
            setData((p) => ({ ...p, treatment_location_id: null, treatment_location_name: '' }))
          }}
          handleSubmit={(e) => {
            setDialog()
            setData((p) => ({ ...p, treatment_location_id: e.id }))
          }}
        />
      )}

      {dialog?.type === 'referrer' && (
        <ReferrerDialog
          data={dialog}
          handleClose={() => {
            setDialog()
            setData((p) => ({
              ...p,
              referrer_id: null,
              referrer_source: '',
              referrer_phone: '',
              referrer_fax: '',
              referrer_company: ''
            }))
          }}
          handleSubmit={(_referrer) => {
            setDialog()
            setData((p) => ({
              ...p,
              referrer_id: _referrer?.id ?? null,
              referrer_source: _referrer?.source ?? '',
              referrer_phone: _referrer?.phone ?? '',
              referrer_fax: _referrer?.fax ?? '',
              referrer_company: _referrer?.company ?? ''
            }))
          }}
        />
      )}

      {dialog?.type === 'physician' && (
        <PhysicianDialog
          data={dialog}
          handleClose={() => {
            setDialog()
            setData((p) => ({
              ...p,
              referrer_id: null,
              referrer_source: '',
              referrer_phone: '',
              referrer_fax: '',
              referrer_company: ''
            }))
          }}
          handleSubmit={(_physician) => {
            setDialog()
            setData((p) => ({
              ...p,
              incident_physician_id: _physician?.id ?? null,
              incident_physician_phone: _physician?.phone ?? '',
              incident_physician_fax: _physician?.fax ?? '',
              incident_physician_npi_no: _physician?.npi_no ?? '',
              incident_physician_name: _physician?.name ?? ''
            }))
          }}
        />
      )}
    </Stack>
  )
}

export default ClientsIncidentForm
