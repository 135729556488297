import React, { forwardRef, useImperativeHandle } from 'react'
import { Button, ListItemText, Stack, TextField, FormControl } from '@mui/material'

import Select from './Select'
import Select2 from './Select2'

import CategoryService from '../../core/usecases/categoryService'

const ServiceFeeForm = forwardRef(({ data, setData = () => {} }, ref) => {
  const [providers, setProviders] = React.useState([])

  React.useEffect(() => {
    Promise.all([CategoryService.getCategories()]).then(([_c, _s]) => {
      setProviders(_c.map((d) => ({ id: d.id, display_name: d.name })))
    })
  }, [])

  useImperativeHandle(ref, () => ({
    fetchFormData() {
      return data
    }
  }))

  return (
    <Stack
      spacing={2}
      sx={{ width: '45vw', margin: '0 auto' }}
    >
      <ListItemText primary='Description' />

      <FormControl
        sx={{
          background: (theme) => theme.palette.white.main,
          width: '100%',
          flex: 1,
          flexShrink: 0,
          flexGrow: 1
        }}
        variant='outlined'
      >
        <TextField
          label='Code'
          variant='outlined'
          value={data.code}
          onChange={(e) => setData((p) => ({ ...p, code: e.target.value }))}
          fullWidth={false}
        />
      </FormControl>

      <FormControl
        sx={{
          background: (theme) => theme.palette.white.main,
          width: '100%',
          flex: 1,
          flexShrink: 0,
          flexGrow: 1
        }}
        variant='outlined'
      >
        <TextField
          label='Description'
          variant='outlined'
          value={data.description}
          onChange={(e) => setData((p) => ({ ...p, description: e.target.value }))}
          fullWidth={false}
        />
      </FormControl>

      <ListItemText primary='Rate' />

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Rate'
            variant='outlined'
            value={data.rate}
            onChange={(e) => setData((p) => ({ ...p, rate: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>

        <Select
          id='unit'
          label='Unit'
          options={['item', 'visit', 'minute', 'unit']}
          value={data.rate_type}
          onChange={(rate_type) => setData((p) => ({ ...p, rate_type }))}
        />

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Quantity'
            variant='outlined'
            value={data.rate_count}
            onChange={(e) => setData((p) => ({ ...p, rate_count: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>

      <ListItemText primary='Defaults' />

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Default Duration'
            variant='outlined'
            disabled
            fullWidth={false}
          />
        </FormControl>

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Value'
            variant='outlined'
            value={data.default_duration}
            onChange={(e) => setData((p) => ({ ...p, default_duration: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Default Units'
            variant='outlined'
            disabled
            fullWidth={false}
          />
        </FormControl>

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Value'
            variant='outlined'
            value={data.default_units}
            onChange={(e) => setData((p) => ({ ...p, default_units: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Default Visits'
            variant='outlined'
            disabled
            fullWidth={false}
          />
        </FormControl>

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Value'
            variant='outlined'
            value={data.default_visits}
            onChange={(e) => setData((p) => ({ ...p, default_visits: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Post Treatment Description'
            variant='outlined'
            disabled
            fullWidth={false}
          />
        </FormControl>

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Value'
            variant='outlined'
            value={data.post_treatment_description}
            onChange={(e) => setData((p) => ({ ...p, post_treatment_description: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>

      <ListItemText primary='Provider Types' />

      {data.provider_types.map((provider, providerIndex) => (
        <Select2
          id='providers'
          label='Provider'
          options={providers}
          value={provider}
          onChange={(provider_id) => {
            const provider_types = (data?.provider_types ?? []).slice()
            provider_types[providerIndex] = provider_id
            setData((p) => ({ ...p, provider_types }))
          }}
        />
      ))}

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
        sx={{ alignItems: 'center', justifyContent: 'center' }}
      >
        <Button
          color='buttonBaseTheme'
          variant='contained'
          elevation={0}
          disableElevation
          sx={{
            textTransform: 'unset',
            lineHeight: '20px',
            borderRadius: '20px',
            minWidth: '100px'
          }}
          onClick={() => {
            const provider_types = (data?.provider_types ?? []).slice()
            provider_types.push('')
            setData((p) => ({ ...p, provider_types }))
          }}
        >
          Add Provider
        </Button>
      </Stack>
    </Stack>
  )
})

export default ServiceFeeForm
