import React from 'react'
import { FormControl, ListItemText, Stack, TextField } from '@mui/material'

import AutoComplete from './AutoComplete'
import _Table from './Table'
import Select from './Select'

import PayorService from '../../core/usecases/payorService'

const ServicePayorForm = ({ data, setData }) => {
  return (
    <Stack spacing={2}>
      <ListItemText primary='Payors' />

      <AutoComplete
        label='Payor'
        value={data?.payor_id}
        handleAPI={PayorService.lookup}
        lazy
        handleOnChange={(payor) =>
          setData((p) => ({
            ...p,
            payor_id: payor?.id ?? null,
            payor_name: payor?.name ?? ''
          }))
        }
        textFieldParams={{
          sx: {
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }
        }}
      />

      <ListItemText primary='Custom Rate' />

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Rate'
            variant='outlined'
            value={data.rate}
            onChange={(e) => setData((p) => ({ ...p, rate: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>

        <Select
          id='unit'
          label='Unit'
          options={['item', 'visit', 'minute', 'unit']}
          value={data.rate_type}
          onChange={(rate_type) => setData((p) => ({ ...p, rate_type }))}
        />

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Quantity'
            variant='outlined'
            value={data.rate_count}
            onChange={(e) => setData((p) => ({ ...p, rate_count: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>

      <ListItemText primary='Defaults' />

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Default Duration'
            variant='outlined'
            disabled
            fullWidth={false}
          />
        </FormControl>

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Value'
            variant='outlined'
            value={data.default_duration}
            onChange={(e) => setData((p) => ({ ...p, default_duration: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Default Units'
            variant='outlined'
            disabled
            fullWidth={false}
          />
        </FormControl>

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Value'
            variant='outlined'
            value={data.default_units}
            onChange={(e) => setData((p) => ({ ...p, default_units: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Default Visits'
            variant='outlined'
            disabled
            fullWidth={false}
          />
        </FormControl>

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Value'
            variant='outlined'
            value={data.default_visits}
            onChange={(e) => setData((p) => ({ ...p, default_visits: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>
    </Stack>
  )
}

export default ServicePayorForm
