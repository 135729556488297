import React from 'react'
import { AppBar, Toolbar } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'

import SideNav from './SideNav'
import Search from './Search'
import AccountMenu from './AccountMenu'

import { useAuth } from '../../context/AuthContext'

const MainLayout = ({ children = <></>, location = '' }) => {
  const { user, logout } = useAuth()

  const getInitials = () => {
    const _u = user?.name ?? ''

    return _u[0] || ''
  }

  return (
    <div className='app-container'>
      <div className='sidenav'>
        <SideNav location={location} />
      </div>
      <div className='main-content'>
        <div className='app-bar'>
          <AppBar
            position='static'
            elevation={0}
            color='appbar'
          >
            <Toolbar>
              <IconButton
                edge='start'
                color='white'
              >
                <MenuIcon />
              </IconButton>
              <Search />
              <AccountMenu
                initials={getInitials()}
                handleLogout={logout}
              />
            </Toolbar>
          </AppBar>
        </div>
        <div className='scrollable-content'>{children}</div>
      </div>
    </div>
  )
}

export default MainLayout
