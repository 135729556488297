import apiService from '../../adapters/api/apiService'

class PayorService {
  async create(payor) {
    const response = await apiService.createPayor(payor)
    return response.data
  }

  async lookup(search) {
    const response = await apiService.getPayors(search)
    return response.data
  }
}

export default new PayorService()
