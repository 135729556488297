import apiService from '../../adapters/api/apiService'

class PackagesService {
  async create(service) {
    const response = await apiService.createPackage(service)
    return response.data
  }

  async get(search = '') {
    const response = await apiService.getPackages(search)
    return response.data
  }

  async getById(id) {
    const response = await apiService.getPackages(id)
    return response.data?.pop() ?? {}
  }
}

export default new PackagesService()
