import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Container,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Tabs,
  Tab,
  Box
} from '@mui/material'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'

import MainLayout from '../common/MainLayout'
import _Table from '../common/Table'
import ClientsClientForm from '../common/ClientsClientForm'
import ClientsDemographicsForm from '../common/ClientsDemographicsForm'
import ClientsPaymentForm from '../common/ClientsPaymentForm'
import ClientsIncidentsList from '../common/ClientsIncidentsList'

import ClientService from '../../core/usecases/clientService'

const initialData = {
  salutation_id: '',
  firstname: '',
  middlename: '',
  lastname: '',
  home_phone: '',
  work_phone: '',
  mobile: '',
  fax: '',
  personal_email: '',
  work_email: '',
  contact_notes: '',
  street: '',
  city: '',
  state: '',
  postal: '',
  country: '',
  provider_id: '',
  e_firstname: '',
  e_lastname: '',
  e_relationship: '',
  e_phone: '',
  file_no: '',
  gender: '',
  birthdate: '',
  marital_status: '',
  ssn: '',
  health_no: '',
  preferred_language: '',
  race: '',
  allergies: '',
  drivers_license_no: '',
  drivers_license_status: '',
  employment_status: '',
  employment_school: '',
  job_title: '',
  occupation: '',
  supervisor: '',
  mode_of_payment: '',
  incidents: []
}

const ClientForm = () => {
  const { clientId } = useParams()
  const navigate = useNavigate()
  const [tab, setTab] = React.useState(0)
  const [data, setData] = React.useState(initialData)
  const [mainNav, setMainNav] = React.useState(true)

  React.useEffect(() => {
    console.log(clientId, 'client id')
    if (clientId) {
      fetchClientData(clientId)
    }
    return () => {
      setData(initialData)
      setMainNav(true)
      setTab(0)
    }
  }, [])

  const fetchClientData = (_clientId) => {
    new Promise((resolve, reject) => {
      ClientService.getById(_clientId)
        .then((res) => {
          const _form = Object.assign({}, res)
          const { birthdate, ...others } = _form
          setData(() => ({ birthdate: dayjs(birthdate), ...others }))
          resolve(res)
        })
        .catch((err) => {
          console.error(`Error fetching client data ${err}`)
          reject(err)
        })
    })
  }

  const handleChangeTab = (event, newValue) => {
    setTab(newValue)
  }

  const handleSave = async () => {
    if (clientId) return
    try {
      await ClientService.create(data)
      navigate('/clients')
    } catch (error) {
      console.error('Error in client module', error)
    }
  }

  return (
    <MainLayout location='Patients'>
      <Stack
        elevation={0}
        sx={{ position: 'relative', display: 'flex', flex: 1 }}
      >
        <Paper
          elevation={0}
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 2
          }}
        >
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            elevation={0}
            sx={{
              background: (theme) => theme.palette.white.main,
              padding: '0 40px'
            }}
          >
            <ListItem>
              <ListItemText
                primary='Patient'
                secondary='You can add or edit patient on this page'
              />
            </ListItem>

            <Stack
              direction='row'
              elevation={0}
              spacing={2}
            >
              <Button
                color='buttonBaseTheme'
                variant='outlined'
                elevation={0}
                disableElevation
                sx={{
                  textTransform: 'unset',
                  lineHeight: '20px',
                  borderRadius: '20px',
                  minWidth: '100px'
                }}
                onClick={() => navigate('/clients')}
              >
                Cancel
              </Button>

              <Button
                color='buttonBaseTheme'
                variant='contained'
                elevation={0}
                disableElevation
                sx={{
                  textTransform: 'unset',
                  lineHeight: '20px',
                  borderRadius: '20px',
                  minWidth: '100px'
                }}
                onClick={handleSave}
              >
                Save
              </Button>
            </Stack>
          </Stack>
        </Paper>

        <Container
          disableGutters
          sx={{
            height: '100%',
            width: '100%',
            overflow: 'hidden'
          }}
        >
          <Paper
            elevation={0}
            sx={{
              background: (theme) => theme.palette.background.main,
              padding: '40px 10%',
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              overflow: 'auto',
              height: '100%'
            }}
          >
            {mainNav && (
              <Box
                sx={{
                  borderBottom: (theme) => `solid 1px ${theme.palette.borderBackground.main}`
                }}
              >
                <Tabs
                  value={tab}
                  onChange={handleChangeTab}
                >
                  <Tab label='Client' />
                  <Tab label='Demographics' />
                  <Tab label='Payment Info' />
                  <Tab label='Incidents' />
                </Tabs>
              </Box>
            )}
            <CustomTabPanel
              value={tab}
              index={0}
            >
              <ClientsClientForm
                data={data}
                setData={setData}
              />
            </CustomTabPanel>
            <CustomTabPanel
              value={tab}
              index={1}
            >
              <ClientsDemographicsForm
                data={data}
                setData={setData}
              />
            </CustomTabPanel>
            <CustomTabPanel
              value={tab}
              index={2}
            >
              <ClientsPaymentForm
                data={data}
                setData={setData}
              />
            </CustomTabPanel>
            <CustomTabPanel
              value={tab}
              index={3}
            >
              <ClientsIncidentsList
                data={data}
                setData={setData}
                handleParentNav={setMainNav}
              />
            </CustomTabPanel>
          </Paper>
        </Container>
      </Stack>
    </MainLayout>
  )
}

const CustomTabPanel = ({ value, index, children }) => {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`cf-tabpanel-${index}`}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

export default ClientForm
