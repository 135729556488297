import React from 'react'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'

import { Button, Container, ListItem, ListItemText, Paper, Stack } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import MainLayout from '../common/MainLayout'
import _Table from '../common/Table'

import ClientService from '../../core/usecases/clientService'

const ClientsList = () => {
  const navigate = useNavigate()
  const [data, setData] = React.useState([])

  React.useEffect(() => {
    initializeTable()
    return () => setData([])
  }, [])

  const initializeTable = async () => {
    try {
      const _l = await ClientService.get()
      setData(_l)
    } catch (error) {
      console.error(error, 'Error initializing clients table')
    }
  }

  return (
    <MainLayout location='Patients'>
      <Stack
        elevation={0}
        sx={{ height: '100%' }}
      >
        <Paper elevation={0}>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            elevation={0}
            sx={{ background: (theme) => theme.palette.white.main, padding: '0 40px' }}
          >
            <ListItem>
              <ListItemText
                primary='Patient'
                secondary='You can add or edit patient on this page'
              />
            </ListItem>

            <Button
              color='primaryLight'
              variant='contained'
              elevation={0}
              disableElevation
              sx={{
                textTransform: 'unset',
                lineHeight: '20px',
                borderRadius: '20px',
                minWidth: '200px'
              }}
              onClick={() => navigate('/clients/create')}
            >
              Add Patient
            </Button>
          </Stack>
        </Paper>

        <Container
          disableGutters
          sx={{ height: '100%' }}
        >
          <Paper
            elevation={0}
            sx={{
              background: (theme) => theme.palette.background.main,
              padding: '40px 10%',
              height: '100%'
            }}
          >
            <_Table
              columns={[
                'Name',
                'Birthdate',
                'Gender',
                'Age',
                'File',
                'Physician',
                'Phone',
                'Division',
                'Incident',
                ''
              ]}
              rows={data.map((c) => [
                c.name,
                dayjs(c.birthdate).format('MM/DD/YYYY'),
                c.gender,
                c.age,
                c.file_no,
                c.physician,
                [c.home_phone, c.work_phone, c.mobile].filter(Boolean).join(', '),
                '',
                '',
                <MoreVertIcon onClick={() => navigate(`/clients/edit/${c.id}`)} />
              ])}
            />
          </Paper>
        </Container>
      </Stack>
    </MainLayout>
  )
}

export default ClientsList
