import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, Container, ListItem, ListItemText, Paper, Stack } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import MainLayout from '../common/MainLayout'
import _Table from '../common/Table'

import ServicesService from '../../core/usecases/servicesService'
import { useAuth } from '../../context/AuthContext'

const ServicesList = () => {
  const { user } = useAuth()
  const navigate = useNavigate()
  const [data, setData] = React.useState([])

  React.useEffect(() => {
    initializeTable()
    return () => setData([])
  }, [])

  const initializeTable = async () => {
    try {
      const _l = await ServicesService.get()
      setData(_l)
    } catch (error) {
      console.error(error, 'Error initializing services table')
    }
  }

  return (
    <MainLayout location='Services'>
      <Stack
        elevation={0}
        sx={{ height: '100%' }}
      >
        <Paper elevation={0}>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            elevation={0}
            sx={{ background: (theme) => theme.palette.white.main, padding: '0 40px' }}
          >
            <ListItem>
              <ListItemText
                primary='Services'
                secondary='List of services code, description, amount, units and locations'
              />
            </ListItem>

            <Button
              color='primaryLight'
              variant='contained'
              elevation={0}
              disableElevation
              sx={{
                textTransform: 'unset',
                lineHeight: '20px',
                borderRadius: '20px',
                minWidth: '200px'
              }}
              onClick={() => navigate('/services/create')}
            >
              Add Service
            </Button>
          </Stack>
        </Paper>

        <Container
          disableGutters
          sx={{ height: '100%' }}
        >
          <Paper
            elevation={0}
            sx={{
              background: (theme) => theme.palette.background.main,
              padding: '40px 10%',
              height: '100%'
            }}
          >
            <_Table
              columns={['Code', 'Description', 'Amount', 'Units', 'Locations', '']}
              rows={data.map((p) => [
                p.code,
                p.description,
                p.rate,
                p.rate_count,
                'All',
                <MoreVertIcon onClick={() => navigate('/services/edit/' + p.code)} />
              ])}
            />
          </Paper>
        </Container>
      </Stack>
    </MainLayout>
  )
}

export default ServicesList
