import React from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'

const _Table = ({ columns = [], rows = [], expand = null }) => {
  return (
    <Paper
      sx={{ width: '100%', overflow: 'hidden' }}
      elevation={0}
    >
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column, k) => (
                <TableCell
                  key={`table-row-column-item-${k}`}
                  // align={column.align}
                  // style={{ minWidth: column.minWidth }}
                  sx={{ background: (theme) => theme.palette.background.dark }}
                  {...(expand && k === expand - 1 && { colSpan: 2 })}
                >
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, k) => {
              return (
                <TableRow key={`table-row-body-item-${k}`}>
                  {row.map((r, _k) => (
                    <TableCell
                      key={`table-row-body-item-${k}-${_k}`}
                      {...(expand && _k === expand - 1 && { colSpan: 2 })}
                    >
                      {r}
                    </TableCell>
                  ))}
                  {/* {columns.map((column) => {
                      // const value = row[column.id];
                      return (
                        // <TableCell
                        //   key={column.id} align={column.align}>
                        //   {column.format && typeof value === 'number'
                        //     ? column.format(value)
                        //     : value}
                        // </TableCell>
                      );
                    })} */}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default _Table
