import React from 'react'
import { Route, defer, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'

import { AuthLayout } from './context/AuthContext'

import Protected from './routes/Protected'
import Login from './components/pages/Login'

const getUserData = () =>
  new Promise(
    (resolve) => {
      const _c = localStorage.getItem('_auth')
      resolve(JSON.parse(_c) || null)
    }
    // setTimeout(() => {
    //   const _c = localStorage.getItem('_auth')
    //   resolve(JSON.parse(_c) || null)
    // }, 200)
  )

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route
        element={<AuthLayout />}
        loader={() => defer({ userPromise: getUserData() })}
      >
        <Route
          path='*'
          element={<Protected />}
        />
        <Route
          path='/login'
          element={<Login />}
        />
      </Route>
    </Route>
  )
)
