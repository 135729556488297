import React from 'react'
import { SketchPicker } from 'react-color'

const ColorPicker = ({ color, onChangeColor = () => {} }) => {
  const [state, setState] = React.useState(
    color || {
      r: 255,
      g: 255,
      b: 255,
      a: 1
    }
  )

  const handleChange = (color) => {
    setState((p) => ({ ...p, ...color.rgb }))
    onChangeColor({ ...color.rgb })
  }

  return (
    <div>
      <div
        style={{
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
          width: '100%'
        }}
      >
        <div
          style={{
            width: '100%',
            height: '14px',
            borderRadius: '2px',
            background: `rgba(${state.r}, ${state.g}, ${state.b}, ${state.a})`
          }}
        />
      </div>
      <SketchPicker
        color={state}
        onChange={handleChange}
      />
    </div>
  )
}

export default ColorPicker
