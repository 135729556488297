import React from 'react'
import { SketchPicker } from 'react-color'

const ColorBox = ({ color, onChangeColor = () => {} }) => {
  const [_color, setColor] = React.useState(
    color || {
      r: 255,
      g: 255,
      b: 255,
      a: 1
    }
  )
  const [displayColorPicker, setDisplayColorPicker] = React.useState(false)

  React.useEffect(() => {
    setColor(
      () =>
        color || {
          r: 255,
          g: 255,
          b: 255,
          a: 1
        }
    )
  }, [color])

  const handleClick = (e) => {
    e.stopPropagation()
    e.preventDefault()
    setDisplayColorPicker(!displayColorPicker)
  }

  const handleClose = () => {
    setDisplayColorPicker(false)
    onChangeColor(_color)
  }

  const handleChange = (color) => {
    setColor(() => ({ ...color.rgb }))
  }

  return (
    <div
      style={{
        height: '50.25px',
        width: '100%',
        backgroundColor: '#FFF',
        border: `solid 1px ${displayColorPicker ? '#3700B3' : '#D6D1D1'}`,
        borderRadius: '4px',
        cursor: 'pointer',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        flexShrink: 0,
        flexGrow: 1
      }}
    >
      <div
        style={{
          height: '80%',
          width: '97%',
          borderRadius: '3px',
          background:
            (_color && `rgba(${_color.r}, ${_color.g}, ${_color.b}, ${_color.a})`) || 'transparent'
        }}
        onClick={handleClick}
      />

      {displayColorPicker ? (
        <div
          style={{
            position: 'absolute',
            zIndex: '2',
            bottom: '115%'
          }}
        >
          <div
            style={{
              position: 'fixed',
              top: '0px',
              right: '0px',
              bottom: '0px',
              left: '0px',
              cursor: 'default'
            }}
            onClick={handleClose}
          />
          <SketchPicker
            color={_color}
            onChange={handleChange}
          />
        </div>
      ) : null}
    </div>
  )
}

export default ColorBox
