import * as React from 'react'
import { IconButton, OutlinedInput, InputLabel, InputAdornment, FormControl } from '@mui/material'
import Visibility from '@mui/icons-material/VisibilityOutlined'
import VisibilityOff from '@mui/icons-material/VisibilityOffOutlined'

const Password = ({ label = '', ...others }) => {
  const [showPassword, setShowPassword] = React.useState(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  return (
    <FormControl
      sx={{
        width: 1,
        background: (theme) => theme.palette.white.main,
        flex: 1,
        flexGrow: 1,
        flexShrink: 0
      }}
      variant='outlined'
    >
      <InputLabel>{label}</InputLabel>
      <OutlinedInput
        type={showPassword ? 'text' : 'password'}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge='end'
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label={label}
        {...others}
      />
    </FormControl>
  )
}

export default Password
