import React, { forwardRef, useImperativeHandle } from 'react'
import { Stack, TextField, FormControl } from '@mui/material'

import MultipleSelect from './MultipleSelect'
import Select from './Select'
import Password from './Password'

const initialData = {
  username: '',
  firstname: '',
  lastname: '',
  roles: [],
  status: '',
  password: '',
  confirmpassword: ''
}

const UserForm = forwardRef((props, ref) => {
  const [data, setData] = React.useState(initialData)

  React.useEffect(() => {
    return () => setData(initialData)
  }, [])

  const handleChange = (e, t) => {
    e.preventDefault()
    setData((p) => ({ ...p, [t]: e.target.value }))
  }

  useImperativeHandle(ref, () => ({
    fetchFormData() {
      return data
    }
  }))

  return (
    <Stack spacing={2}>
      <FormControl
        sx={{ background: (theme) => theme.palette.white.main }}
        variant='outlined'
      >
        <TextField
          label='User Name'
          variant='outlined'
          value={data.username || ''}
          onChange={(e) => setData((p) => ({ ...p, username: e.target.value }))}
        />
      </FormControl>

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='First Name'
            variant='outlined'
            value={data.firstname}
            onChange={(e) => handleChange(e, 'firstname')}
            fullWidth={false}
          />
        </FormControl>

        <FormControl
          sx={{
            background: (theme) => theme.palette.white.main,
            width: '100%',
            flex: 1,
            flexShrink: 0,
            flexGrow: 1
          }}
          variant='outlined'
        >
          <TextField
            label='Last Name'
            variant='outlined'
            value={data.lastname}
            onChange={(e) => setData((p) => ({ ...p, lastname: e.target.value }))}
            fullWidth={false}
          />
        </FormControl>
      </Stack>

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <MultipleSelect
          id='roles'
          options={['Admin', 'Staff']}
          onChange={(roles) => setData((p) => ({ ...p, roles }))}
          label='Select Role'
        />

        <Select
          id='status'
          label='Select Status'
          options={['Active', 'Inactive']}
          onChange={(status) => setData((p) => ({ ...p, status }))}
        />
      </Stack>

      <Stack
        direction='row'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
      >
        <Password
          label='Password'
          value={data.password}
          onChange={(e) => setData((p) => ({ ...p, password: e.target.value }))}
        />

        <Password
          label='Confirm Password'
          value={data.confirmpassword}
          onChange={(e) => setData((p) => ({ ...p, confirmpassword: e.target.value }))}
        />
      </Stack>
    </Stack>
  )
})

export default UserForm
